import * as signalR from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { negotiateSignalRAccessToken } from "./api";

export interface UseHubMessageProps {
  hubUrl: string;
  methodName: string;
}

export const useHubMessage = <T>({
  hubUrl,
  methodName,
}: UseHubMessageProps) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );

  const [message, setMessage] = useState<{
    methodName: string;
    data: T;
  } | null>(null);

  useEffect(() => {
    if (!connection) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl, {
          accessTokenFactory: async () => {
            const { token } = await negotiateSignalRAccessToken();
            return token;
          },
        } as signalR.IHttpConnectionOptions)
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [hubUrl, connection, setConnection]);

  useEffect(() => {
    if (!connection) {
      return;
    }
    const stop = () => {
      connection
        ?.stop()
        .then(() => console.log("Disconnected from hub!"))
        .catch((error) => console.log(`Failed to disconnect from hub!`, error));
    };
    if (connection.state === signalR.HubConnectionState.Connected) {
      return stop;
    }
    connection.on(methodName, (...args) => {
      console.log(`Received call to ${methodName}`, args);
      let data;
      if (methodName === "append_eval") {
        data = args;
      } else {
        data = args[0];
      }
      setMessage({
        methodName,
        data,
      });
    });
    if (connection.state !== signalR.HubConnectionState.Disconnected) {
      return;
    }
    connection
      .start()
      .then(() => console.log(`Connected to hub!`))
      .catch((error) => console.error(`Failed to connect to hub!`, error));
    return stop;
  }, [connection, methodName]);

  return message;
};
