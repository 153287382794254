import { Box, useTheme } from "@mui/material";
import {
  Datum,
  Layout,
  LegendClickEvent,
  PlotData,
  PlotMouseEvent,
} from "plotly.js";
import Plot from "react-plotly.js";
import { StrategySignalSeriesEntry } from "../../types";
import useWindowDimensions from "../../useWindowDimensions";
import { useStrategyMonitoringStatsAndControlsContext } from "../StatsAndControls";
import { useStrategyMonitoringFiltersContext } from "../useStrategyMonitoringFiltersContext";
import {
  strategyMonitoringSignalSeriesConfig,
  strategyMonitoringSignalSeriesOrder,
} from "./SignalSeriesConfig";
import { StrategyMonitoringSignalSeriesConfigEntry } from "./SignalSeriesConfig/SignalSeriesConfig";
import { useStrategyMonitoringLiveSignalsContext } from "./useLiveSignalsContext";
import { useStrategyMonitoringSignalsContext } from "./useSignalsContext";

const pricesAndModelsDataContainerName = "Prices & Models & EMAs";
const slopesDataContainerName = "Slopes";
const spreadsAndZscoresDataContainerName = "Spreads & ZScores";
const containerNamesBySeries: { [key: string]: string[] } = {
  prices: [pricesAndModelsDataContainerName],
  slopes: [slopesDataContainerName],
  spreads: [spreadsAndZscoresDataContainerName],
  zscores: [spreadsAndZscoresDataContainerName],
  maxmin: [],
  trade_direction: [pricesAndModelsDataContainerName, slopesDataContainerName],
  position_direction: [spreadsAndZscoresDataContainerName],
};
const containerPlotProps: { [containerName: string]: Partial<PlotData> } = {
  [pricesAndModelsDataContainerName]: {
    type: "scatter",
    mode: "lines",
    showlegend: true,
  },
  [slopesDataContainerName]: {
    type: "scatter",
    mode: "lines",
    showlegend: true,
  },
  [spreadsAndZscoresDataContainerName]: {
    type: "scatter",
    mode: "lines",
    showlegend: true,
  },
};
const containerPlotPropsOverrides: { [series: string]: Partial<PlotData> } = {
  prices: {},
  slopes: {},
  spreads: {},
  zscores: {},
  maxmin: {
    type: "scatter",
    mode: "markers",
    showlegend: true,
  },
  trade_direction: {
    type: "scatter",
    mode: "markers",
    showlegend: true,
    marker: {
      color: "#ff3d00",
      size: 8,
    },
    yaxis: "y2",
  },
  position_direction: {
    type: "scatter",
    mode: "markers",
    showlegend: true,
    marker: {
      color: "#ff3d00",
      size: 8,
    },
    yaxis: "y2",
  },
};

const plotLayoutYAxis2Value = 1;
const plotLayoutYAxis2Props: Partial<Layout["yaxis2"]> = {
  overlaying: "y",
  side: "right",
  autorange: true,
  range: [-plotLayoutYAxis2Value, plotLayoutYAxis2Value],
  constraintoward: "center",
};

type StrategySignalSeriesDataContainer = {
  [series: string]: Partial<PlotData>;
};

export const StrategyMonitoringSignalsPlots = () => {
  const theme = useTheme();
  const { width, height } = useWindowDimensions();

  const {
    filters: { strategy },
    setPeriodIndex,
  } = useStrategyMonitoringFiltersContext();
  const { isLoadingStats } = useStrategyMonitoringStatsAndControlsContext();
  const { isLoadingSignals, resolveSeriesVisibility, toggleSeriesVisibility } =
    useStrategyMonitoringSignalsContext();
  const { signals } = useStrategyMonitoringLiveSignalsContext();

  if (isLoadingStats || isLoadingSignals) {
    return null;
  }

  const seriesConfig = strategyMonitoringSignalSeriesConfig[strategy.name];
  const seriesOrder = strategyMonitoringSignalSeriesOrder[strategy.name];
  if (!seriesConfig || !seriesOrder) {
    return null;
  }

  const containers: {
    [containerName: string]: StrategySignalSeriesDataContainer;
  } = {
    [pricesAndModelsDataContainerName]: {},
    [slopesDataContainerName]: {},
    [spreadsAndZscoresDataContainerName]: {},
  };
  const containerBounds: {
    [containerName: string]: { min: number; max: number };
  } = {
    [pricesAndModelsDataContainerName]: {
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER,
    },
    [slopesDataContainerName]: {
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER,
    },
    [spreadsAndZscoresDataContainerName]: {
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER,
    },
  };

  const resolveSeriesData = (
    containerName: string,
    entryConfig: StrategyMonitoringSignalSeriesConfigEntry,
    entry: StrategySignalSeriesEntry
  ) => {
    const container = containers[containerName];
    let data = container[entry.label];
    if (!data) {
      const plotProps = containerPlotProps[containerName];
      const plotPropsOverrides = containerPlotPropsOverrides[entry.series];
      const entryOverrides = entryConfig.overrides ?? {};

      data = {
        ...plotProps,
        ...plotPropsOverrides,
        ...entryOverrides,
        marker: {
          ...(plotProps.marker ?? {}),
          ...(plotPropsOverrides.marker ?? {}),
          ...(entryOverrides.marker ?? {}),
          color: entryConfig.color,
          size: entryConfig.size,
        },
        name: entryConfig.label,
        visible: resolveSeriesVisibility(
          `${containerName}:${entryConfig.label}`
        ),
        text: [],
        x: [],
        y: [],
      };
    }
    return data;
  };

  const signalsCount = signals.length;
  for (let signalIdx = 0; signalIdx < signalsCount; signalIdx++) {
    const signal = signals[signalIdx];

    for (const entry of signal.series_entries) {
      if (entry.series === "maxmin") {
        continue;
      }

      const seriesEntryConfig = seriesConfig[entry.label];
      if (!seriesEntryConfig) {
        continue;
      }

      const containerNames = containerNamesBySeries[entry.series];
      for (const containerName of containerNames) {
        const [text, x, y] = seriesEntryConfig.resolveTextAndXAndY(
          entry,
          signal,
          signalIdx,
          signalsCount
        );

        const data = resolveSeriesData(containerName, seriesEntryConfig, entry);
        (data.text as string[]).push(text);
        (data.x as Datum[]).push(x);
        (data.y as Datum[]).push(y);
        containers[containerName][entry.label] = data;

        const bounds = containerBounds[containerName];
        bounds.max = Math.max(bounds.max, entry.value);
        bounds.min = Math.min(bounds.min, entry.value);
        containerBounds[containerName] = bounds;
      }
    }
  }
  for (let signalIdx = 0; signalIdx < signalsCount; signalIdx++) {
    const signal = signals[signalIdx];

    for (const entry of signal.series_entries) {
      if (entry.series !== "maxmin") {
        continue;
      }

      const seriesEntryConfig = seriesConfig[entry.label];
      if (!seriesEntryConfig) {
        continue;
      }
      const [text, x, y] = seriesEntryConfig.resolveTextAndXAndY(
        entry,
        signal,
        signalIdx,
        signalsCount
      );

      const containerName = pricesAndModelsDataContainerName;
      const data = resolveSeriesData(containerName, seriesEntryConfig, entry);
      (data.text as string[]).push(text);
      (data.x as Datum[]).push(x);
      (data.y as Datum[]).push(y);
      containers[containerName][entry.label] = data;
    }
  }

  const plotLayoutProps: Partial<Layout> = {
    width: width,
    height: height * 0.7,
    paper_bgcolor: theme.palette.background.paper,
    plot_bgcolor: theme.palette.background.paper,
    font: { color: theme.palette.text.primary },
    legend: { orientation: "h", itemclick: "toggle", itemdoubleclick: false },
    yaxis: { side: "left", autorange: true },
  };

  const onClick = (event: PlotMouseEvent) => {
    const selectedPoint = event.points[0];
    if (selectedPoint) {
      setPeriodIndex(selectedPoint.x as number);
    }
  };

  const plotConfigs: Array<[Partial<Layout>, Partial<PlotData>[]]> = [
    pricesAndModelsDataContainerName,
    slopesDataContainerName,
    spreadsAndZscoresDataContainerName,
  ].map((containerName) => {
    const y2MaxValue = plotLayoutYAxis2Value;
    const y2MinValue = -plotLayoutYAxis2Value;

    const y1Bounds = containerBounds[containerName];
    const y1MaxValue = Math.max(y2MaxValue, Math.ceil(y1Bounds.max));
    const y1MinValue = Math.min(y2MinValue, Math.floor(y1Bounds.min));

    // TODO: align zeroline if visible in both y axes.

    return [
      {
        ...plotLayoutProps,
        title: containerName,
        yaxis: {
          ...plotLayoutProps.yaxis,
          side: "left",
          range: [y1MinValue, y1MaxValue],
        },
        yaxis2: plotLayoutYAxis2Props,
      },
      Object.values(containers[containerName]).sort((d1, d2) => {
        const d1Order = seriesOrder.indexOf(d1.name as string);
        const d2Order = seriesOrder.indexOf(d2.name as string);
        return d1Order - d2Order;
      }),
    ];
  });
  const plotComponents = plotConfigs.map((plotConfig, plotIdx) => {
    const [containerPlotLayoutProps, plotData] = plotConfig;
    const onLegendClick = (event: LegendClickEvent): boolean => {
      const item = event.data[event.curveNumber];
      toggleSeriesVisibility(`${containerPlotLayoutProps.title}:${item.name}`);
      return false;
    };
    return (
      <Box key={`plot-${plotIdx}`}>
        <Plot
          layout={{
            ...plotLayoutProps,
            ...containerPlotLayoutProps,
          }}
          onClick={onClick}
          data={plotData}
          onLegendClick={onLegendClick}
        />
      </Box>
    );
  });
  return <>{plotComponents}</>;
};
