import { Stack } from "@mui/material";
import { Loader } from "../Loader";
import { PerformanceReportsForm } from "./PerformanceReportsForm";
import { PerformanceReportsResult } from "./PerformanceReportsResult";
import { usePerformanceReportsContext } from "./usePerformanceReportsContext";

export const PerformanceReportsContainer = () => {
  const { isLoadingBenchmarkOptions } = usePerformanceReportsContext();
  if (isLoadingBenchmarkOptions) {
    return <Loader />;
  }
  return (
    <Stack direction="column">
      <PerformanceReportsForm />
      <PerformanceReportsResult />
    </Stack>
  );
};
