import React from "react";
import { RequiredApiAuthDetailsContextProvider } from "./RequiredApiAuthDetailsContextProvider";
import { ApiAuthDetailsContext } from "./useApiAuthDetailsContext";

export interface ApiAuthDetailsContextProviderProps {
  children: React.ReactNode;
}

export const ApiAuthDetailsContextProvider = ({
  children,
}: ApiAuthDetailsContextProviderProps) => {
  const { tenantId, tenantClientId, tenantClientSecret, tenantApiScope } =
    window.dashboardAppConfig;

  if (tenantId && tenantClientId && tenantClientSecret && tenantApiScope) {
    return (
      <RequiredApiAuthDetailsContextProvider
        tenantId={tenantId}
        tenantClientId={tenantClientId}
        tenantClientSecret={tenantClientSecret}
        tenantApiScope={tenantApiScope}
      >
        {children}
      </RequiredApiAuthDetailsContextProvider>
    );
  }

  return (
    <ApiAuthDetailsContext.Provider value={{ apiAuthDetails: null }}>
      {children}
    </ApiAuthDetailsContext.Provider>
  );
};
