import { Box, MenuItem, Stack, TextField, TextFieldProps } from "@mui/material";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useDashboardContext } from "../Dashboard/useDashboardContext";
import { Strategy } from "../types";
import { useStrategyMonitoringFiltersContext } from "./useStrategyMonitoringFiltersContext";

const buildStrategyKey = (strategy: Strategy): string =>
  `${strategy.account.name}:${strategy.instrument.name}:${strategy.name}`;

const commonDatePickerProps: DateTimePickerProps<Dayjs> = {
  disableFuture: true,
  slotProps: {
    textField: {
      size: "small",
    },
  },
};

export const StrategyMonitoringFilters = () => {
  const { strategies } = useDashboardContext();
  const { filters, setFilters } = useStrategyMonitoringFiltersContext();
  const { strategy, startDate, endDate } = filters;

  const strategiesByKey = strategies.reduce<{ [key: string]: Strategy }>(
    (dict, strategy) => {
      const key = buildStrategyKey(strategy);
      dict[key] = strategy;
      return dict;
    },
    {}
  );
  const onChangeStrategy: TextFieldProps["onChange"] = (event) => {
    const strategyKey = event.target.value;
    setFilters({
      ...filters,
      strategy: strategiesByKey[strategyKey],
    });
  };

  const strategyKey = buildStrategyKey(strategy);

  const onChangeStartDate: DateTimePickerProps<Dayjs>["onChange"] = (value) => {
    value && setFilters({ ...filters, startDate: value.toDate() });
  };
  const onChangeEndDate: DateTimePickerProps<Dayjs>["onChange"] = (value) => {
    value && setFilters({ ...filters, endDate: value.toDate() });
  };

  return (
    <Stack
      direction="row"
      paddingX={8}
      paddingY={4}
      justifyContent="space-between"
    >
      <TextField
        id="selectedStrategy"
        label="Strategy"
        variant="outlined"
        size="small"
        select
        value={strategyKey}
        onChange={onChangeStrategy}
      >
        {strategies.map((strategy) => {
          const strategyKey = buildStrategyKey(strategy);
          return (
            <MenuItem key={strategyKey} value={strategyKey}>
              {strategy.account.name} - {strategy.instrument.name} -{" "}
              {strategy.name}
            </MenuItem>
          );
        })}
      </TextField>
      <Stack direction="row" spacing={2} alignItems="center">
        <DateTimePicker
          {...commonDatePickerProps}
          value={dayjs(startDate)}
          onChange={onChangeStartDate}
        />
        <Box>-</Box>
        <DateTimePicker
          {...commonDatePickerProps}
          value={dayjs(endDate || new Date())}
          onChange={onChangeEndDate}
        />
      </Stack>
    </Stack>
  );
};
