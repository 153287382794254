import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { StrategySignalSwitchRuleConditionEntry } from "../../../types";

export interface StrategyMonitoringSignalTableSwitchRuleConditionsTableProps {
  expandConditionResults: boolean;
  ruleLabel: string;
  conditionEntries: Array<StrategySignalSwitchRuleConditionEntry>;
}
export const StrategyMonitoringSignalTableSwitchRuleConditionsTable = ({
  expandConditionResults,
  ruleLabel,
  conditionEntries,
}: StrategyMonitoringSignalTableSwitchRuleConditionsTableProps) => {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={expandConditionResults} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Condition</TableCell>
                  <TableCell>Expression</TableCell>
                  <TableCell>Evaluation</TableCell>
                  <TableCell>Satisfied</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(conditionEntries).map((conditionResult) => (
                  <TableRow key={`${ruleLabel}:${conditionResult.label}`}>
                    <TableCell component="th" scope="row">
                      {conditionResult.label}
                    </TableCell>
                    <TableCell>{conditionResult.expr}</TableCell>
                    <TableCell>{conditionResult.eval_expr}</TableCell>
                    <TableCell>
                      {conditionResult.value ? "YES" : "NO"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
