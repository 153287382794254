import {
  AppBar,
  Toolbar,
  Typography,
  Stack,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import { dashboardSections, useDashboardContext } from "./useDashboardContext";
import { appTitle } from "../constants";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useAppConfigContext } from "../useAppConfigContext";

export const DashboardAppBar = () => {
  const { themeMode, toggleThemeMode } = useAppConfigContext();
  const { section, navigateTo } = useDashboardContext();
  const theme = useTheme();

  return (
    <AppBar position="relative">
      <Toolbar>
        <Typography variant="h6" color={theme.palette.text.primary} noWrap>
          {appTitle}
        </Typography>
        <Stack direction="row" spacing={3} paddingX={4}>
          <IconButton onClick={toggleThemeMode} color="default" size="small">
            {themeMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {Object.values(dashboardSections).map((dashboardSection) => (
            <Button
              variant="text"
              key={dashboardSection}
              onClick={() => navigateTo(dashboardSection)}
              disabled={section === dashboardSection}
            >
              <Typography
                color={
                  section === dashboardSection
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                variant="subtitle2"
              >
                {dashboardSection.toUpperCase()}
              </Typography>
            </Button>
          ))}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
