import { Box, Grid, Typography } from "@mui/material";
import { ActionControls } from "./ActionControls";
import { PositionStats } from "./PositionStats";
import { useStrategyMonitoringStatsAndControlsContext } from "./useStatsAndControlsContext";

export const StrategyMonitoringStatsAndControls = () => {
  const { isLoadingStats } = useStrategyMonitoringStatsAndControlsContext();

  if (isLoadingStats) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      paddingX={8}
      paddingY={4}
      rowGap={2}
      alignItems="center"
    >
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={2}
          >
            <Typography variant="h6">Position Stats</Typography>
            <PositionStats />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={2}
          >
            <Typography variant="h6">Controls</Typography>
            <ActionControls />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
