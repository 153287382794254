import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import {
  Datum,
  Layout,
  LegendClickEvent,
  PlotData,
  PlotMarker,
  PlotMouseEvent,
} from "plotly.js";
import Plot from "react-plotly.js";
import { Loader } from "../Loader";
import { StrategyEvalEntry } from "../types";
import useWindowDimensions from "../useWindowDimensions";
import { useStrategyMonitoringEvalsContext } from "./useStrategyMonitoringEvalsContext";
import { useStrategyMonitoringFiltersContext } from "./useStrategyMonitoringFiltersContext";
import { useStrategyMonitoringLiveEvalsContext } from "./useStrategyMonitoringLiveEvalsContext";

const seriesGroups = {
  priceAndEMAsAndModels: "Price, EMAs & Models",
  slopes: "Slopes",
  spreadsAndZscores: "Spreads & Zscores",
} as const;

const seriesGroupTypes = {
  price: "price",
  slope: "slope",
  spread: "spread",
  zscore: "zscore",
} as const;
type SeriesGroupType = keyof typeof seriesGroupTypes;
const seriesGroupsBySeriesType = {
  [seriesGroupTypes.price]: seriesGroups.priceAndEMAsAndModels,
  [seriesGroupTypes.slope]: seriesGroups.slopes,
  [seriesGroupTypes.spread]: seriesGroups.spreadsAndZscores,
  [seriesGroupTypes.zscore]: seriesGroups.spreadsAndZscores,
} as const;

const fixedSeriesNames = {
  submin: "submin",
  submax: "submax",
  mdlmin: "mdlmin",
  mdlmax: "mdlmax",
  tradeDir0: "tradeDir0",
  tradeDir1: "tradeDir1",
  positionDir: "positionDir",
} as const;

const colorCodesBySeriesName: { [key: string]: string } = {
  almond: "#EFDECD",
  aluminum: "#848484",
  beech: "#7B4F2A",
  birch: "#FFE4C4",
  black: "#696969",
  blue: "#0000FF",
  box: "#987654",
  bronze: "#CD7F32",
  brown: "#964B00",
  cedar: "#A45A52",
  cherry: "#DE3163",
  clear: "#D8D8D8",
  copper: "#B87333",
  cyan: "#00FFFF",
  gold: "#FFD700",
  green: "#008000",
  grey: "#808080",
  iron: "#444C52",
  lead: "#212121",
  magenta: "#FF00FF",
  mahogany: "#5C4033",
  maple: "#DB6514",
  nickel: "#727472",
  oak: "#806517",
  opal: "#A8C3BC",
  orange: "#FFA500",
  pink: "#FFC0CB",
  price_10: "#5E123F",
  price_20: "#6E239D",
  price_a5: "#7A34A3",
  price_n: "#3E65A1",
  field_value: "#FFDAB9",
  price: "#FFDAB9",
  purple: "#800080",
  red: "#FF0000",
  rust: "#B7410E",
  silver: "#C0C0C0",
  tan: "#D2B48C",
  teal: "#008080",
  tin: "#7F7F7F",
  walnut: "#79443B",
  white: "#FFFFFF",
  yellow: "#FFFF00",
  [fixedSeriesNames.submin]: "#8E4585",
  [fixedSeriesNames.submax]: "#007FFF",
  [fixedSeriesNames.mdlmin]: "#FF7F50",
  [fixedSeriesNames.mdlmax]: "#808000",
  [fixedSeriesNames.tradeDir0]: "#ff3d00",
  [fixedSeriesNames.tradeDir1]: "#ff3d00",
  [fixedSeriesNames.positionDir]: "#ff3d00",
};

interface SeriesProperties {
  name: string;
  marker: Partial<PlotMarker>;
  yaxis?: PlotData["yaxis"];
  resolveY: (entry: StrategyEvalEntry, idx: number, length: number) => Datum;
}
const fixedSeriesPropertiesBySeriesNames = {
  [fixedSeriesNames.tradeDir0]: {
    name: "TRADE DIR",
    marker: {
      size: 8,
      color: colorCodesBySeriesName[fixedSeriesNames.tradeDir0],
    },
    yaxis: "y2",
    resolveY: (entry) => entry.trade_dir as number,
  } as SeriesProperties,
  [fixedSeriesNames.tradeDir1]: {
    name: "TRADE DIR",
    marker: {
      size: 8,
      color: colorCodesBySeriesName[fixedSeriesNames.tradeDir1],
    },
    resolveY: (entry) => entry.trade_dir as number,
  } as SeriesProperties,
  [fixedSeriesNames.positionDir]: {
    name: "POSITION DIR",
    marker: {
      size: 8,
      color: colorCodesBySeriesName[fixedSeriesNames.positionDir],
    },
    resolveY: (entry) => {
      const position = entry.eval_data["POSITION"] as number;
      if (position === 0) {
        return 0;
      }
      return position / Math.abs(position);
    },
  } as SeriesProperties,
  [fixedSeriesNames.submin]: {
    name: "SUB MIN",
    marker: {
      size: 12,
      color: colorCodesBySeriesName[fixedSeriesNames.submin],
    },
    resolveY: (entry, idx, length) =>
      idx == length - 1 ? (entry.eval_data["SUBMIN"] as number) : undefined,
  } as SeriesProperties,
  [fixedSeriesNames.submax]: {
    name: "SUB MAX",
    marker: {
      size: 12,
      color: colorCodesBySeriesName[fixedSeriesNames.submax],
    },
    resolveY: (entry, idx, length) =>
      idx == length - 1 ? (entry.eval_data["SUBMAX"] as number) : undefined,
  } as SeriesProperties,
  [fixedSeriesNames.mdlmin]: {
    name: "MDL MIN",
    marker: { size: 8, color: colorCodesBySeriesName[fixedSeriesNames.mdlmin] },
    resolveY: (entry, idx, length) =>
      idx == length - 1 ? (entry.eval_data["MDLMIN"] as number) : undefined,
  } as SeriesProperties,
  [fixedSeriesNames.mdlmax]: {
    name: "MDL MAX",
    marker: { size: 8, color: colorCodesBySeriesName[fixedSeriesNames.mdlmax] },
    resolveY: (entry, idx, length) =>
      idx == length - 1 ? (entry.eval_data["MDLMAX"] as number) : undefined,
  } as SeriesProperties,
} as const;

const fixedSeriesNamesBySeriesGroup = {
  [seriesGroups.priceAndEMAsAndModels]: [
    fixedSeriesNames.tradeDir0,
    fixedSeriesNames.submin,
    fixedSeriesNames.submax,
    fixedSeriesNames.mdlmin,
    fixedSeriesNames.mdlmax,
  ],
  [seriesGroups.slopes]: [fixedSeriesNames.tradeDir1],
  [seriesGroups.spreadsAndZscores]: [fixedSeriesNames.positionDir],
} as const;

const layoutsBySeriesGroup = {
  [seriesGroups.priceAndEMAsAndModels]: {
    yaxis2: {
      overlaying: "y",
      side: "right",
      autorange: false,
      range: [-1.25, 1.25],
    },
  } as Partial<Layout>,
  [seriesGroups.slopes]: {} as Partial<Layout>,
  [seriesGroups.spreadsAndZscores]: {} as Partial<Layout>,
} as const;

export const StrategyMonitoringEvalData = () => {
  const {
    filters: { strategy },
    setPeriodIndex,
  } = useStrategyMonitoringFiltersContext();
  const { isLoadingEvals, resolveSeriesVisibility, toggleSeriesVisibility } =
    useStrategyMonitoringEvalsContext();
  const { evals } = useStrategyMonitoringLiveEvalsContext();

  const theme = useTheme();
  const { width, height } = useWindowDimensions();

  if (isLoadingEvals) {
    return <Loader />;
  }

  const commonPlotLayout: Partial<Layout> = {
    width: width,
    height: height * 0.7,
    paper_bgcolor: theme.palette.background.paper,
    plot_bgcolor: theme.palette.background.paper,
    font: { color: theme.palette.text.primary },
    legend: { orientation: "h", itemclick: "toggle", itemdoubleclick: false },
    yaxis: {
      side: "left",
    },
  };

  const evalsLength = evals.length;
  const plotLayoutAndDataBySeriesGroup = evals.reduce<{
    [key: string]: {
      layout: Partial<Layout>;
      data: {
        [key: string]: Partial<PlotData> & {
          text: string[];
          x: Datum[];
          y: Datum[];
        };
      };
    };
  }>((dict, entry, idx) => {
    for (const [seriesGroupType, seriesItems] of Object.entries(
      strategy.series
    )) {
      const textValue = dayjs(entry.period).format("YYYY-MM-DD HH:mm");
      const xValue = idx;

      const seriesGroup =
        seriesGroupsBySeriesType[seriesGroupType as SeriesGroupType];
      let seriesGroupLayoutAndData = dict[seriesGroup];
      if (!seriesGroupLayoutAndData) {
        seriesGroupLayoutAndData = {
          layout: {
            ...commonPlotLayout,
            ...layoutsBySeriesGroup[seriesGroup],
            title: seriesGroup,
          },
          data: {},
        };
      }

      for (const seriesItem of seriesItems) {
        let seriesData = seriesGroupLayoutAndData.data[seriesItem.name];
        if (!seriesData) {
          seriesData = {
            type: "scatter",
            mode: "lines",
            marker: {
              color: colorCodesBySeriesName[seriesItem.label.toLowerCase()],
            },
            name: `${seriesItem.name} (${seriesItem.label})`,
            text: [],
            showlegend: true,
            x: [],
            y: [],
          };
        }
        seriesData.text.push(textValue);
        seriesData.x.push(xValue);
        seriesData.y.push(entry.eval_data[`${seriesItem.name}_LAST`] as number);
        seriesData.visible = resolveSeriesVisibility(
          `${seriesGroup}:${seriesItem.name} (${seriesItem.label})`
        );
        seriesGroupLayoutAndData.data[seriesItem.name] = seriesData;
      }

      const fixedSeriesNames = fixedSeriesNamesBySeriesGroup[seriesGroup];
      for (const fixedSeriesName of fixedSeriesNames) {
        const fixedSeriesProperties =
          fixedSeriesPropertiesBySeriesNames[fixedSeriesName];

        let fixedSeriesData = seriesGroupLayoutAndData.data[fixedSeriesName];
        if (!fixedSeriesData) {
          fixedSeriesData = {
            type: "scatter",
            mode: "markers",
            marker: fixedSeriesProperties.marker,
            name: fixedSeriesProperties.name,
            text: [],
            showlegend: true,
            x: [],
            y: [],
            yaxis: fixedSeriesProperties.yaxis,
          };
        }
        fixedSeriesData.text.push(textValue);
        fixedSeriesData.x.push(xValue);
        fixedSeriesData.y.push(
          fixedSeriesProperties.resolveY(entry, idx, evalsLength)
        );
        fixedSeriesData.visible = resolveSeriesVisibility(
          `${seriesGroup}:${fixedSeriesProperties.name}`
        );
        seriesGroupLayoutAndData.data[fixedSeriesName] = fixedSeriesData;
      }

      dict[seriesGroup] = seriesGroupLayoutAndData;
    }
    return dict;
  }, {});

  const onClick = (event: PlotMouseEvent) => {
    const selectedPoint = event.points[0];
    if (selectedPoint) {
      setPeriodIndex(selectedPoint.x as number);
    }
  };
  const fixedSeriesNameKeys = Object.keys(fixedSeriesNames);
  const plots = Object.keys(plotLayoutAndDataBySeriesGroup).map(
    (seriesGroup) => {
      const { layout, data } = plotLayoutAndDataBySeriesGroup[seriesGroup];
      const hasMoreSeriesOtherThanFixedOnes =
        Object.keys(data).filter(
          (key) => fixedSeriesNameKeys.indexOf(key) === -1
        ).length > 0;
      if (!hasMoreSeriesOtherThanFixedOnes) {
        return null;
      }
      const onLegendClick = (event: LegendClickEvent): boolean => {
        const item = event.data[event.curveNumber];
        toggleSeriesVisibility(`${seriesGroup}:${item.name}`);
        return false;
      };
      return (
        <Box key={`plot-${seriesGroup}`}>
          <Plot
            layout={layout}
            data={Object.values(data)}
            onClick={onClick}
            onLegendClick={onLegendClick}
          />
        </Box>
      );
    }
  );

  return <>{plots}</>;
};
