import { PerformanceReportTradesSource, Strategy } from "../../types";

export const isValidNumericValue = (valueStr: string): boolean => {
  const value = Number(valueStr || "0");
  return !isNaN(value);
};

export const isSubmittableNumericValue = (valueStr: string): boolean => {
  if (!isValidNumericValue(valueStr)) {
    return false;
  }
  const value = Number(valueStr);
  return value > 0;
};

export const mapStrategyToTradesSource = (
  strategy: Strategy
): PerformanceReportTradesSource => ({
  source_type: "IBKR",
  source_id: strategy.account.id,
  source_label: strategy.account.name,
  instrument_id: strategy.instrument.id,
  instrument_label: strategy.instrument.name,
  allocation: 0,
});

export const buildTradesSourceKey = (
  tradesSource: PerformanceReportTradesSource
): string =>
  [
    tradesSource.source_type,
    tradesSource.source_id,
    tradesSource.instrument_id,
  ].join(":");
