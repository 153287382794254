import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { FetchStrategyEvalsParams, fetchStrategyEvals } from "../api";
import { StrategyEvals } from "../types";
import {
  StrategyMonitoringEvalsContext,
  StrategyMonitoringEvalsContextState,
} from "./useStrategyMonitoringEvalsContext";
import { useStrategyMonitoringFiltersContext } from "./useStrategyMonitoringFiltersContext";

const visibilityByStrategyAndSeriesKeyItemKey =
  "StrategyMonitoringEvalsContextProvider.visibilityByStrategyAndSeriesKey";

export interface StrategyMonitoringEvalsContextProviderProps {
  children: React.ReactNode;
}
export const StrategyMonitoringEvalsContextProvider = ({
  children,
}: StrategyMonitoringEvalsContextProviderProps) => {
  const {
    filters: { strategy, startDate, endDate },
  } = useStrategyMonitoringFiltersContext();

  const evalsMutation = useMutation<
    StrategyEvals,
    unknown,
    FetchStrategyEvalsParams
  >(async (params) => {
    const evals = await fetchStrategyEvals(params);
    return evals.sort((e1, e2) => dayjs(e1.period).diff(dayjs(e2.period)));
  });
  const { data: evals = [], isLoading: isLoadingEvals } = evalsMutation;

  useEffect(() => {
    evalsMutation.mutate({
      strategy,
      startDate,
      endDate: endDate || new Date(),
    });
  }, [strategy, startDate, endDate]);

  const strategyKey = `${strategy.account.name}:${strategy.instrument.name}:${strategy.name}`;

  const [
    visibilityByStrategyAndSeriesKey,
    setVisibilityByStrategyAndSeriesKey,
  ] = useState<
    StrategyMonitoringEvalsContextState["visibilityByStrategyAndSeriesKey"]
  >(() => {
    const item = localStorage.getItem(visibilityByStrategyAndSeriesKeyItemKey);
    if (!item) {
      return {};
    }
    return JSON.parse(item);
  });
  const toggleSeriesVisibility = useCallback(
    (seriesName: string) =>
      setVisibilityByStrategyAndSeriesKey((currentState) => {
        const strategyItem = currentState[strategyKey] || {};
        return {
          ...currentState,
          [strategyKey]: {
            ...strategyItem,
            [seriesName]:
              strategyItem[seriesName] === undefined ||
              strategyItem[seriesName] === null
                ? false
                : !strategyItem[seriesName],
          },
        };
      }),
    [strategyKey, setVisibilityByStrategyAndSeriesKey]
  );
  const resolveSeriesVisibility = useCallback(
    (seriesKey: string): boolean | "legendonly" => {
      const strategyItem = visibilityByStrategyAndSeriesKey[strategyKey] || {};
      const value =
        strategyItem[seriesKey] === undefined ||
        strategyItem[seriesKey] === null ||
        strategyItem[seriesKey];
      return value ? true : "legendonly";
    },
    [strategyKey, visibilityByStrategyAndSeriesKey]
  );

  useEffect(() => {
    if (visibilityByStrategyAndSeriesKey) {
      localStorage.setItem(
        visibilityByStrategyAndSeriesKeyItemKey,
        JSON.stringify(visibilityByStrategyAndSeriesKey)
      );
    }
  }, [visibilityByStrategyAndSeriesKey]);

  return (
    <StrategyMonitoringEvalsContext.Provider
      value={{
        evals,
        isLoadingEvals,
        visibilityByStrategyAndSeriesKey,
        resolveSeriesVisibility,
        toggleSeriesVisibility,
      }}
    >
      {children}
    </StrategyMonitoringEvalsContext.Provider>
  );
};
