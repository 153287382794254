import { createContext, useContext } from "react";
import { noop } from "../helpers";
import { StrategyMonitoringFilters } from "./useStrategyMonitoringFiltersFromQueryParams";

export interface StrategyMonitoringFiltersContextState {
  filters: StrategyMonitoringFilters;
  setFilters: (
    filters: StrategyMonitoringFilters
  ) =>
    | void
    | ((prevFilters: StrategyMonitoringFilters) => StrategyMonitoringFilters);
  periodIndex: number | null;
  setPeriodIndex: (periodIndex: number) => void;
}

export const StrategyMonitoringFiltersContext =
  createContext<StrategyMonitoringFiltersContextState>({
    filters: {
      strategy: {
        account: {
          id: 0,
          name: "",
        },
        instrument: {
          id: 0,
          name: "",
        },
        periodicity: {
          id: 0,
          name: "",
        },
        app_id: 0,
        name: "",
        series: {
          price: [],
          spread: [],
          zscore: [],
          slope: [],
        },
      },
      startDate: new Date(),
      endDate: null,
    },
    setFilters: noop,
    periodIndex: null,
    setPeriodIndex: noop,
  });

export const useStrategyMonitoringFiltersContext = () =>
  useContext(StrategyMonitoringFiltersContext);
