import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import {
  fetchBenchmarkOptions,
  createQCTradesCollection as requestToCreateQCTradesCollection,
} from "../api";
import {
  GeneratePerformanceReportRequestParams,
  PerformanceReportAssessmentInterval,
  PerformanceReportBenchmark,
  PerformanceReportTargetInterval,
  PerformanceReportTradesSource,
  QCTradesCollection,
  performanceReportAssessmentIntervals,
  performanceReportTargetIntervals,
} from "../types";
import {
  PerformanceReportsContext,
  PerformanceReportsContextState,
} from "./usePerformanceReportsContext";

export interface PerformanceReportsContextProviderProps {
  children: React.ReactNode;
}
export const PerformanceReportsContextProvider = ({
  children,
}: PerformanceReportsContextProviderProps) => {
  const [params, setParams] = useState<GeneratePerformanceReportRequestParams>({
    name: "CST",
    qc_trades_collection: null,
    start_date: dayjs("2022-01-01").toDate(),
    end_date: dayjs().toDate(),
    risk_free_rate: "",
    target_interval: performanceReportTargetIntervals.monthly,
    management_fee_rate: "",
    management_fee_assessment_interval:
      performanceReportAssessmentIntervals.quarterly,
    management_fee_assessment_arrears: false,
    incentive_fee_rate: "",
    incentive_fee_assessment_interval:
      performanceReportAssessmentIntervals.quarterly,
    trades_sources: [],
    benchmarks: [],
  });

  const setName = useCallback(
    (name: string) => setParams((state) => ({ ...state, name })),
    [setParams]
  );

  const [isCreatingQCTradesCollection, setIsCreatingQCTradesCollection] =
    useState<boolean>(false);
  const setQCTradesCollection = useCallback(
    (qc_trades_collection: QCTradesCollection) =>
      setParams((state) => ({
        ...state,
        qc_trades_collection,
        start_date: dayjs(qc_trades_collection.start_date).toDate(),
        end_date: dayjs(qc_trades_collection.end_date).toDate(),
        trades_sources: qc_trades_collection.trades_sources,
      })),
    [setParams]
  );
  const createQCTradesCollection: PerformanceReportsContextState["createQCTradesCollection"] =
    useCallback(
      (files) => {
        setIsCreatingQCTradesCollection(true);
        requestToCreateQCTradesCollection(files)
          .then((collection) => setQCTradesCollection(collection))
          .catch((error) => console.error(error))
          .finally(() => setIsCreatingQCTradesCollection(false));
      },
      [setIsCreatingQCTradesCollection, setQCTradesCollection]
    );
  useEffect(() => {
    if (params.trades_sources.length === 0 && !!params.qc_trades_collection) {
      setParams((state) => ({
        ...state,
        qc_trades_collection: null,
        start_date: dayjs("2022-01-01").toDate(),
        end_date: dayjs().toDate(),
      }));
    }
  }, [params, setParams]);

  const setStartDate = useCallback(
    (start_date: Date) => setParams((state) => ({ ...state, start_date })),
    [setParams]
  );
  const setEndDate = useCallback(
    (end_date: Date) => setParams((state) => ({ ...state, end_date })),
    [setParams]
  );
  const setRiskFreeRate = useCallback(
    (risk_free_rate: string) =>
      setParams((state) => ({ ...state, risk_free_rate })),
    [setParams]
  );
  const setTargetInterval = useCallback(
    (target_interval: PerformanceReportTargetInterval) =>
      setParams((state) => ({ ...state, target_interval })),
    [setParams]
  );
  const setManagementFeeRate = useCallback(
    (management_fee_rate: string) =>
      setParams((state) => ({ ...state, management_fee_rate })),
    [setParams]
  );
  const setManagementFeeAssessmentInterval = useCallback(
    (management_fee_assessment_interval: PerformanceReportAssessmentInterval) =>
      setParams((state) => ({ ...state, management_fee_assessment_interval })),
    [setParams]
  );
  const toggleManagementFeeAssessmentArrears = useCallback(
    () =>
      setParams((state) => ({
        ...state,
        management_fee_assessment_arrears:
          !state.management_fee_assessment_arrears,
      })),
    [setParams]
  );
  const setIncentiveFeeRate = useCallback(
    (incentive_fee_rate: string) =>
      setParams((state) => ({ ...state, incentive_fee_rate })),
    [setParams]
  );
  const setIncentiveFeeAssessmentInterval = useCallback(
    (incentive_fee_assessment_interval: PerformanceReportAssessmentInterval) =>
      setParams((state) => ({ ...state, incentive_fee_assessment_interval })),
    [setParams]
  );

  const addTradesSource = useCallback(
    (trades_source: PerformanceReportTradesSource) =>
      setParams((state) => ({
        ...state,
        trades_sources: [...state.trades_sources, trades_source],
      })),
    [setParams]
  );
  const updateTradesSourceAllocation = useCallback(
    (index_to_update: number, allocation: number) =>
      setParams((state) => ({
        ...state,
        trades_sources: state.trades_sources.map((trades_source, index) => {
          if (index !== index_to_update) {
            return trades_source;
          }
          return { ...trades_source, allocation };
        }),
      })),
    [setParams]
  );
  const removeTradesSource = useCallback(
    (index_to_remove: number) =>
      setParams((state) => ({
        ...state,
        trades_sources: state.trades_sources.filter(
          (_, index) => index !== index_to_remove
        ),
      })),
    [setParams]
  );

  const { data: benchmarkOptions, isLoading: isLoadingBenchmarkOptions } =
    useQuery<PerformanceReportBenchmark[]>({
      queryKey: ["performance_reports", "benchmarkOptions"],
      queryFn: fetchBenchmarkOptions,
      initialData: [],
    });
  const setBenchmarks = useCallback(
    (instruments: string[]) => {
      setParams((state) => ({
        ...state,
        benchmarks: benchmarkOptions.filter((benchmark) =>
          instruments.includes(benchmark.instrument_label)
        ),
      }));
    },
    [setParams, benchmarkOptions]
  );

  const [generateRequestId, setGenerateRequestId] = useState<string>("");

  return (
    <PerformanceReportsContext.Provider
      value={{
        params,
        setName,
        isCreatingQCTradesCollection,
        createQCTradesCollection,
        minStartDate: params?.qc_trades_collection?.start_date
          ? dayjs(params?.qc_trades_collection?.start_date)
          : undefined,
        setStartDate,
        maxEndDate: params?.qc_trades_collection?.end_date
          ? dayjs(params?.qc_trades_collection?.end_date)
          : undefined,
        setEndDate,
        setRiskFreeRate,
        setTargetInterval,
        setManagementFeeRate,
        setManagementFeeAssessmentInterval,
        toggleManagementFeeAssessmentArrears,
        setIncentiveFeeRate,
        setIncentiveFeeAssessmentInterval,
        addTradesSource,
        updateTradesSourceAllocation,
        removeTradesSource,
        benchmarkOptions,
        isLoadingBenchmarkOptions,
        setBenchmarks,
        generateRequestId,
        setGenerateRequestId,
      }}
    >
      {children}
    </PerformanceReportsContext.Provider>
  );
};
