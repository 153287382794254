import { formatPercentage } from "../../helpers";
import {
  PerformanceReportResult,
  PerformanceReportsResultSeries,
} from "../../types";
import { PerformanceReportsResultTable } from "./Table";

export const columns = [
  "Series",
  "3 Months",
  "6 Months",
  "1 Year",
  "YTD",
  "ITD",
];

export interface PerformanceReportsResultReturnsSummaryTableProps {
  reportRequestName: string;
  reportRequestTag: string;
  selectedSeries: PerformanceReportsResultSeries[];
  selectedBenchmarks: string[];
  result: PerformanceReportResult;
}
export const PerformanceReportsResultReturnsSummaryTable = ({
  reportRequestName,
  reportRequestTag,
  selectedSeries,
  selectedBenchmarks,
  result,
}: PerformanceReportsResultReturnsSummaryTableProps) => {
  const data: Array<Array<string | number>> = [];

  let lines = [...selectedSeries.map((series) => result[series])];
  if (selectedBenchmarks.includes(reportRequestName)) {
    lines.push(result.benchmark);
  }
  lines = [
    ...lines,
    ...result.benchmarks.filter((benchmark) =>
      selectedBenchmarks.includes(benchmark.type)
    ),
  ];
  for (const line of lines) {
    data.push([
      line.label.replaceAll("MOMENTUM", reportRequestName),
      formatPercentage(line.returns_summary.three_months, 2),
      formatPercentage(line.returns_summary.six_months, 2),
      formatPercentage(line.returns_summary.one_year, 2),
      formatPercentage(line.returns_summary.year_to_date, 2),
      formatPercentage(line.returns_summary.inception_to_date, 2),
    ]);
  }

  return (
    <PerformanceReportsResultTable
      columns={columns}
      data={data}
      csvFileName={`${reportRequestTag} - Returns Summary.csv`}
    />
  );
};
