import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useStrategyMonitoringLiveEvalsContext } from "./useStrategyMonitoringLiveEvalsContext";

export const StrategyMonitoringEvalDataAndResultsHeader = () => {
  const { evalsUpdatedAt } = useStrategyMonitoringLiveEvalsContext();
  return (
    <Box paddingX={8} display="flex" justifyContent="flex-end">
      <Typography variant="caption">
        As of {dayjs(evalsUpdatedAt).format("YYYY-MM-DD HH:mm")}
      </Typography>
    </Box>
  );
};
