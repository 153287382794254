import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { useDashboardContext } from "../../Dashboard/useDashboardContext";
import { Loader } from "../../Loader";
import { retrievePerformanceReportResult } from "../../api";
import {
  GeneratePerformanceReportRequestParams,
  PerformanceReportResult,
  PerformanceReportSignalRMessage,
  PerformanceReportsResultSeries,
  performanceReportsResultSeries,
} from "../../types";
import { useHubMessage } from "../../useSignalR";
import { usePerformanceReportsContext } from "../usePerformanceReportsContext";
import { PerformanceReportsResultFeeRatesTable } from "./FeeRatesTable";
import { PerformanceReportsResultFeesTable } from "./FeesTable";
import { PerformanceReportsResultMetricsTable } from "./MetricsTable";
import { PerformanceReportsResultPlots } from "./Plots";
import { PerformanceReportsResultRawDataTable } from "./RawDataTable";
import { PerformanceReportsResultReturnsSummaryTable } from "./ReturnsSummaryTable";
import { PerformanceReportsResultSeriesChooser } from "./SeriesChooser";
import { PerformanceReportsResultValueChangesTable } from "./ValueChangesTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const buildReportRequestTag = (
  params: GeneratePerformanceReportRequestParams
) =>
  [
    dayjs(params.start_date).format("YYYYMMDD"),
    dayjs(params.end_date).format("YYYYMMDD"),
    `RFR-${params.risk_free_rate}`,
    `TI-${params.target_interval}`,
    `MF-${params.management_fee_assessment_interval}-${params.management_fee_rate}`,
    `IF-${params.incentive_fee_assessment_interval}-${params.incentive_fee_rate}`,
  ].join("_");

export const PerformanceReportsResult = () => {
  const { hubUrl } = useDashboardContext();
  const { params, generateRequestId } = usePerformanceReportsContext();

  const reportRequestName = params.name;
  const reportRequestTag = buildReportRequestTag(params);

  const [reportRequestId, setReportRequestId] =
    useState<string>(generateRequestId);
  const [reportResult, setReportResult] =
    useState<PerformanceReportResult | null>(null);
  const reportMessage = useHubMessage<PerformanceReportSignalRMessage>({
    hubUrl,
    methodName: "show_performance_report",
  });
  useEffect(() => {
    if (generateRequestId !== reportRequestId) {
      setReportRequestId(generateRequestId);
      setReportResult(null);
    }
  }, [generateRequestId]);
  useEffect(() => {
    if (
      reportRequestId &&
      reportMessage &&
      reportRequestId === reportMessage.data.request_id
    ) {
      if (reportRequestId !== reportMessage.data.request_id) {
        console.info(
          `Ignoring performance report ${reportMessage.data.request_id}, waiting for ${reportRequestId} instead!`
        );
      } else {
        retrievePerformanceReportResult(reportMessage.data.report_id)
          .then((result) => setReportResult(result))
          .catch((error) => console.log(error));
      }
    }
  }, [reportRequestId, reportMessage, setReportResult]);

  const [selectedSeries, setSelectedSeries] = useState<
    PerformanceReportsResultSeries[]
  >([
    performanceReportsResultSeries.momentum,
    performanceReportsResultSeries.net_of_fees,
  ]);

  const [selectedBenchmarks, setSelectedBenchmarks] = useState<string[]>([]);
  useEffect(() => {
    if (reportResult) {
      setSelectedBenchmarks(
        [{ label: reportRequestName }, ...reportResult.benchmarks].map(
          (benchmark) => benchmark.label
        )
      );
    }
  }, [reportResult, setSelectedBenchmarks]);

  if (reportRequestId && !reportResult) {
    return <Loader />;
  }
  if (!reportResult) {
    return null;
  }

  return (
    <>
      <PerformanceReportsResultSeriesChooser
        reportRequestName={reportRequestName}
        selectedSeries={selectedSeries}
        setSelectedSeries={setSelectedSeries}
        selectedBenchmarks={selectedBenchmarks}
        setSelectedBenchmarks={setSelectedBenchmarks}
        result={reportResult}
      />
      <PerformanceReportsResultPlots
        reportRequestName={reportRequestName}
        selectedSeries={selectedSeries}
        selectedBenchmarks={selectedBenchmarks}
        result={reportResult}
      />
      <PerformanceReportsResultReturnsSummaryTable
        reportRequestName={reportRequestName}
        reportRequestTag={reportRequestTag}
        selectedSeries={selectedSeries}
        selectedBenchmarks={selectedBenchmarks}
        result={reportResult}
      />
      {selectedSeries.includes(performanceReportsResultSeries.net_of_fees) && (
        <PerformanceReportsResultFeeRatesTable
          reportRequestTag={reportRequestTag}
          result={reportResult}
        />
      )}
      {selectedSeries.includes(performanceReportsResultSeries.net_of_fees) && (
        <PerformanceReportsResultFeesTable
          managementFeeAssessmentArrears={
            params.management_fee_assessment_arrears
          }
          reportRequestTag={reportRequestTag}
          result={reportResult}
        />
      )}
      <PerformanceReportsResultRawDataTable
        reportRequestTag={reportRequestTag}
        selectedSeries={selectedSeries}
        result={reportResult}
      />
      <PerformanceReportsResultMetricsTable
        reportRequestName={reportRequestName}
        reportRequestTag={reportRequestTag}
        selectedSeries={selectedSeries}
        selectedBenchmarks={selectedBenchmarks}
        result={reportResult}
      />
      <PerformanceReportsResultValueChangesTable
        reportRequestName={reportRequestName}
        reportRequestTag={reportRequestTag}
        selectedSeries={selectedSeries}
        result={reportResult}
      />
    </>
  );
};
