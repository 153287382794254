import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Loader } from "../Loader";
import { Alert, AlertTitle } from "@mui/material";
import { ApiAuthDetailsContext } from "./useApiAuthDetailsContext";

interface AccessTokenResponse {
  token_type: string;
  expires_in: number; // in seconds
  access_token: string;
}
const requestAccessToken = async ({
  tenantId,
  tenantClientId,
  tenantClientSecret,
  tenantApiScope,
}: {
  tenantId: string;
  tenantClientId: string;
  tenantClientSecret: string;
  tenantApiScope: string;
}): Promise<AccessTokenResponse> => {
  const url = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
  const formData = new URLSearchParams({
    client_id: tenantClientId,
    client_secret: tenantClientSecret,
    scope: tenantApiScope,
    grant_type: "client_credentials",
  });
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formData.toString(),
  });
  return (await response.json()) as AccessTokenResponse;
};

export interface RequiredApiAuthDetailsContextProviderProps {
  tenantId: string;
  tenantClientId: string;
  tenantClientSecret: string;
  tenantApiScope: string;
  children: React.ReactNode;
}

export const RequiredApiAuthDetailsContextProvider = ({
  tenantId,
  tenantClientId,
  tenantClientSecret,
  tenantApiScope,
  children,
}: RequiredApiAuthDetailsContextProviderProps) => {
  const accessTokenMutation = useMutation<AccessTokenResponse>({
    mutationFn: () =>
      requestAccessToken({
        tenantId,
        tenantClientId,
        tenantClientSecret,
        tenantApiScope,
      }),
  });
  const {
    data: accessToken,
    isIdle: isAccessTokenIdle,
    isLoading: isAccessTokenLoading,
    mutate: refetchAccessToken,
  } = accessTokenMutation;

  useEffect(() => {
    refetchAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      const refetchAfterMs = accessToken.expires_in * 1000;
      const refetchTimeoutId = setTimeout(refetchAccessToken, refetchAfterMs);
      return () => clearTimeout(refetchTimeoutId);
    }
  }, [accessToken, refetchAccessToken]);

  if (isAccessTokenIdle || isAccessTokenLoading) {
    return <Loader />;
  }
  if (!accessToken) {
    return (
      <Alert severity="warning">
        <AlertTitle>API Auth Failed</AlertTitle>
        Seems like we could not auth correctly with the API.
      </Alert>
    );
  }

  return (
    <ApiAuthDetailsContext.Provider
      value={{
        apiAuthDetails: {
          authHeader: `${accessToken.token_type} ${accessToken.access_token}`,
        },
      }}
    >
      {children}
    </ApiAuthDetailsContext.Provider>
  );
};
