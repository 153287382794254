import dayjs, { Dayjs } from "dayjs";
import { createContext, useContext } from "react";
import { noop } from "../helpers";
import {
  GeneratePerformanceReportRequestParams,
  PerformanceReportAssessmentInterval,
  PerformanceReportBenchmark,
  PerformanceReportTargetInterval,
  PerformanceReportTradesSource,
  performanceReportAssessmentIntervals,
  performanceReportTargetIntervals,
} from "../types";

export interface PerformanceReportsContextState {
  params: GeneratePerformanceReportRequestParams;
  setName: (name: string) => void;
  minStartDate?: Dayjs;
  setStartDate: (date: Date) => void;
  maxEndDate?: Dayjs;
  setEndDate: (date: Date) => void;
  setRiskFreeRate: (value: string) => void;
  setTargetInterval: (value: PerformanceReportTargetInterval) => void;
  setManagementFeeRate: (value: string) => void;
  setManagementFeeAssessmentInterval: (
    value: PerformanceReportAssessmentInterval
  ) => void;
  toggleManagementFeeAssessmentArrears: () => void;
  setIncentiveFeeRate: (value: string) => void;
  setIncentiveFeeAssessmentInterval: (
    value: PerformanceReportAssessmentInterval
  ) => void;
  isCreatingQCTradesCollection: boolean;
  createQCTradesCollection: (files: FileList) => void;
  addTradesSource: (value: PerformanceReportTradesSource) => void;
  updateTradesSourceAllocation: (index: number, value: number) => void;
  removeTradesSource: (index: number) => void;
  benchmarkOptions: Array<PerformanceReportBenchmark>;
  isLoadingBenchmarkOptions: boolean;
  setBenchmarks: (instruments: string[]) => void;
  generateRequestId: string;
  setGenerateRequestId: (id: string) => void;
}

export const PerformanceReportsContext =
  createContext<PerformanceReportsContextState>({
    params: {
      name: "CST",
      qc_trades_collection: null,
      start_date: dayjs("2022-01-01").toDate(),
      end_date: dayjs().toDate(),
      risk_free_rate: "",
      target_interval: performanceReportTargetIntervals.monthly,
      management_fee_rate: "",
      management_fee_assessment_interval:
        performanceReportAssessmentIntervals.quarterly,
      management_fee_assessment_arrears: false,
      incentive_fee_rate: "",
      incentive_fee_assessment_interval:
        performanceReportAssessmentIntervals.quarterly,
      trades_sources: [],
      benchmarks: [],
    },
    setName: noop,
    isCreatingQCTradesCollection: false,
    createQCTradesCollection: noop,
    minStartDate: dayjs("2022-01-01"),
    setStartDate: noop,
    maxEndDate: dayjs(),
    setEndDate: noop,
    setRiskFreeRate: noop,
    setTargetInterval: noop,
    setManagementFeeRate: noop,
    setManagementFeeAssessmentInterval: noop,
    toggleManagementFeeAssessmentArrears: noop,
    setIncentiveFeeRate: noop,
    setIncentiveFeeAssessmentInterval: noop,
    addTradesSource: noop,
    updateTradesSourceAllocation: noop,
    removeTradesSource: noop,
    benchmarkOptions: [],
    isLoadingBenchmarkOptions: false,
    setBenchmarks: noop,
    generateRequestId: "",
    setGenerateRequestId: noop,
  });

export const usePerformanceReportsContext = () =>
  useContext(PerformanceReportsContext);
