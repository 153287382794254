import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../../Dashboard/useDashboardContext";
import { StrategySignal } from "../../types";
import { useHubMessage } from "../../useSignalR";
import { useStrategyMonitoringFiltersContext } from "../useStrategyMonitoringFiltersContext";
import { StrategyMonitoringLiveSignalsContext } from "./useLiveSignalsContext";
import { useStrategyMonitoringSignalsContext } from "./useSignalsContext";

export interface StrategyMonitoringLiveSignalsContextProviderProps {
  children: React.ReactNode;
}
export const StrategyMonitoringLiveSignalsContextProvider = ({
  children,
}: StrategyMonitoringLiveSignalsContextProviderProps) => {
  const { hubUrl } = useDashboardContext();
  const {
    filters: { strategy },
    setPeriodIndex,
  } = useStrategyMonitoringFiltersContext();
  const { signals: loadedSignals } = useStrategyMonitoringSignalsContext();

  const [signals, setSignals] = useState<StrategySignal[]>(loadedSignals);
  const [signalsUpdatedAt, setSignalsUpdatedAt] = useState<Date>(new Date());

  const signalMessage = useHubMessage<StrategySignal>({
    hubUrl,
    methodName: "append_signal",
  });
  useEffect(() => {
    setSignals(loadedSignals);
  }, [loadedSignals, setSignals]);
  useEffect(() => {
    if (signalMessage) {
      const entry = signalMessage.data;

      const accountName = entry.ibkr_account_name;
      const instrumentName = entry.instrument_name;
      const strategyName = entry.strategy_name;
      if (
        accountName !== strategy.account.name ||
        instrumentName !== strategy.instrument.name ||
        strategyName !== strategy.name
      ) {
        console.log(
          `Ignoring entry (${accountName}+${instrumentName}+${strategyName}) because that's not the selected strategy!`
        );
        return;
      }
      const period = dayjs(entry.period);
      setSignals((prevSignals) => {
        const existingSignalEntryIdx = prevSignals.findIndex((existingEntry) =>
          period.isSame(existingEntry.period)
        );
        const newSignals: StrategySignal[] = [...prevSignals];
        if (existingSignalEntryIdx >= 0) {
          newSignals[existingSignalEntryIdx] = entry;
        } else {
          newSignals.push(entry);
        }
        return newSignals;
      });
      setSignalsUpdatedAt(new Date());
    }
  }, [strategy, signalMessage, setSignals, setSignalsUpdatedAt]);

  useEffect(() => {
    if (signals.length > 0) {
      setPeriodIndex(signals.length - 1);
    }
  }, [setPeriodIndex, signals]);

  return (
    <StrategyMonitoringLiveSignalsContext.Provider
      value={{
        signals,
        signalsUpdatedAt,
      }}
    >
      {children}
    </StrategyMonitoringLiveSignalsContext.Provider>
  );
};
