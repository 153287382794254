import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { CSVLink } from "react-csv";

export interface PerformanceReportsResultTableProps {
  columns: Array<string>;
  data: Array<Array<string | number>>;
  footer?: Array<string | number | null>;
  csvFileName: string;
}
export const PerformanceReportsResultTable = ({
  columns,
  data,
  footer,
  csvFileName,
}: PerformanceReportsResultTableProps) => {
  const csvData: Array<{ [key: string]: string | number }> = [];
  for (const entry of data) {
    const csvEntry: { [key: string]: string | number } = {};
    for (let i = 0; i < columns.length; i++) {
      csvEntry[columns[i]] = entry[i];
    }
    csvData.push(csvEntry);
  }

  return (
    <Box paddingX={8} paddingY={4}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell key="download" size="small">
                <CSVLink data={csvData} filename={csvFileName} target="_blank">
                  Download CSV
                </CSVLink>
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column}>{column.toUpperCase()}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIdx) => (
              <TableRow key={`${rowIdx}`} style={{ border: "unset" }}>
                <TableCell key={`download:${rowIdx}`} size="small" />
                {row.map((cell, cellIdx) => (
                  <TableCell key={`${columns[rowIdx]}:${rowIdx}:${cellIdx}`}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          {footer && (
            <TableFooter>
              <TableRow>
                <TableCell key="download" size="small" />
                {footer.map((value, idx) => (
                  <TableCell key={idx}>{value || ""}</TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
