import { dashboardSections, useDashboardContext } from "./useDashboardContext";
import { StrategyMonitoring } from "../StrategyMonitoring";
import { PerformanceReports } from "../PerformanceReports";

export const DashboardContent = () => {
  const { section } = useDashboardContext();

  if (section === dashboardSections.monitoring) {
    return <StrategyMonitoring />;
  }
  if (section === dashboardSections.performance) {
    return <PerformanceReports />;
  }

  return null;
};
