import {
  StrategyMonitoringSignalSeriesConfig,
  StrategyMonitoringSignalSeriesOrder,
  resolveTextAndXAndY,
} from "../SignalSeriesConfig";
import colors from "../colors";

export const config: StrategyMonitoringSignalSeriesConfig = {
  ["PRICE"]: {
    label: "PRICE",
    color: colors.field,
    resolveTextAndXAndY,
  },
  ["GREEN"]: {
    label: "GREEN",
    color: colors.green,
    resolveTextAndXAndY,
  },
  ["BLUE"]: {
    label: "BLUE",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  ["PINK"]: {
    label: "PINK",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  ["TAN"]: {
    label: "TAN",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  ["PURPLE"]: {
    label: "PURPLE",
    color: colors.purple,
    resolveTextAndXAndY,
  },
  ["WHITE"]: {
    label: "WHITE",
    color: colors.white,
    resolveTextAndXAndY,
  },
  ["LT_SMOOTH"]: {
    label: "LT_SMOOTH",
    color: colors.opal,
    resolveTextAndXAndY,
  },
  ["LT_PEAK"]: {
    label: "LT_PEAK",
    color: colors.cherry,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["LT_PEAK_TOKEN"]: {
    label: "LT_PEAK_TOKEN",
    color: colors.cedar,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["LT_PEAK_SMOOTH"]: {
    label: "LT_PEAK_SMOOTH",
    color: colors.clear,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["LT_PEAK_DIST"]: {
    label: "LT_PEAK_DIST",
    color: colors.copper,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["XXXLT_SLP_SMOOTH"]: {
    label: "XXXLT_SLP_SMOOTH",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  ["XXXLT_SLP_PEAK"]: {
    label: "XXXLT_SLP_PEAK",
    color: colors.iron,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["XXXLT_SLP_PEAK_TOKEN"]: {
    label: "XXXLT_SLP_PEAK_TOKEN",
    color: colors.lead,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["XXXLT_SLP_PEAK_SMOOTH"]: {
    label: "XXXLT_SLP_PEAK_SMOOTH",
    color: colors.magenta,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["XXXLT_SLP_PEAK_DIST"]: {
    label: "XXXLT_SLP_PEAK_DIST",
    color: colors.maple,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["BLACK10"]: {
    label: "BLACK10",
    color: colors.black,
    resolveTextAndXAndY,
  },
  ["RED10"]: {
    label: "RED10",
    color: colors.red,
    resolveTextAndXAndY,
  },
  ["TRADE DIR"]: {
    label: "TRADEDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
  ["POSITION DIR"]: {
    label: "POSDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
};

export const order: StrategyMonitoringSignalSeriesOrder = [
  "PRICE",
  "GREEN",
  "BLUE",
  "PINK",
  "TAN",
  "PURPLE",
  "WHITE",
  "LT_SMOOTH",
  "LT_PEAK",
  "LT_PEAK_TOKEN",
  "LT_PEAK_SMOOTH",
  "LT_PEAK_DIST",
  "XXXLT_SLP_SMOOTH",
  "XXXLT_SLP_PEAK",
  "XXXLT_SLP_PEAK_TOKEN",
  "XXXLT_SLP_PEAK_SMOOTH",
  "XXXLT_SLP_PEAK_DIST",
  "BLACK10",
  "RED10",
  "TRADE DIR",
  "POSITION DIR",
];
