import { createContext, useContext } from "react";
import { noop } from "../helpers";
import { StrategyEvals } from "../types";

export interface StrategyMonitoringEvalsContextState {
  evals: StrategyEvals;
  isLoadingEvals: boolean;
  visibilityByStrategyAndSeriesKey: {
    [strategeyKey: string]: { [seriesKey: string]: boolean };
  };
  resolveSeriesVisibility: (seriesKey: string) => boolean | "legendonly";
  toggleSeriesVisibility: (seriesKey: string) => void;
}

export const StrategyMonitoringEvalsContext =
  createContext<StrategyMonitoringEvalsContextState>({
    evals: [],
    isLoadingEvals: false,
    visibilityByStrategyAndSeriesKey: {},
    resolveSeriesVisibility: () => true,
    toggleSeriesVisibility: noop,
  });

export const useStrategyMonitoringEvalsContext = () =>
  useContext(StrategyMonitoringEvalsContext);
