import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import qs from "qs";
import { Strategy, StrategySignal } from "../types";
import { baseUrl } from "./common";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface FetchStrategySignalsParams {
  strategy: Strategy;
  startDate: Date;
  endDate: Date;
}
export const fetchStrategySignals = async ({
  strategy,
  startDate,
  endDate,
}: FetchStrategySignalsParams): Promise<StrategySignal[]> => {
  const params = qs.stringify({
    start_date: dayjs
      .tz(startDate, "America/New_York")
      .format("YYYY-MM-DDTHH:mm"),
    end_date: dayjs.tz(endDate, "America/New_York").format("YYYY-MM-DDTHH:mm"),
  });
  const response = await fetch(
    `${baseUrl}/strategies/${strategy.app_id}/signals?${params}`,
    {
      method: "GET",
      headers: { Accept: "application/json" },
    }
  );
  const result = (await response.json()) as StrategySignal[];
  return result;
};
