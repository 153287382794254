import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { StrategyConditionEvalResult } from "../../types";

export interface StrategyMonitoringConditionEvalResultTableProps {
  expandConditionResults: boolean;
  ruleTag: string;
  conditionResults: Array<StrategyConditionEvalResult>;
}
export const StrategyMonitoringConditionEvalResultTable = ({
  expandConditionResults,
  ruleTag,
  conditionResults,
}: StrategyMonitoringConditionEvalResultTableProps) => {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={expandConditionResults} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Condition</TableCell>
                  <TableCell>Expression</TableCell>
                  <TableCell>Evaluation</TableCell>
                  <TableCell>Satisfied</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(conditionResults).map((conditionResult) => (
                  <TableRow key={`${ruleTag}+${conditionResult.condition_tag}`}>
                    <TableCell component="th" scope="row">
                      {conditionResult.condition_tag}
                    </TableCell>
                    <TableCell>{conditionResult.expression}</TableCell>
                    <TableCell>{conditionResult.evaluation}</TableCell>
                    <TableCell>
                      {conditionResult.value ? "YES" : "NO"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
