import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../../Dashboard/useDashboardContext";
import { fetchStrategyStats } from "../../api";
import {
  Strategy,
  StrategyAllocationSignalRMessage,
  StrategyModeSignalRMessage,
  StrategyPositionSignalRMessage,
  StrategySignalRMessage,
  StrategyStats,
  strategyModes,
  strategyPositionStates,
} from "../../types";
import { useHubMessage } from "../../useSignalR";
import { useStrategyMonitoringFiltersContext } from "../useStrategyMonitoringFiltersContext";
import { StrategyMonitoringStatsAndControlsContext } from "./useStatsAndControlsContext";

const handleSignalRMessage = <T extends StrategySignalRMessage>(
  strategy: Strategy,
  message: {
    methodName: string;
    data: T;
  } | null,
  refetchStats: () => void
) => {
  if (!message) {
    return;
  }
  const { ibkr_username, instrument_name, strategy_name, target } =
    message.data;
  if (
    ibkr_username !== strategy.account.name ||
    instrument_name !== strategy.instrument.name ||
    strategy_name !== strategy.name
  ) {
    console.info(
      `${ibkr_username} + ${instrument_name} + ${strategy_name} is not selected, ignoring update ${target} event!`
    );
    return;
  }
  refetchStats();
};

export interface StrategyMonitoringStatsAndControlsContextProviderProps {
  children: React.ReactNode;
}
export const StrategyMonitoringStatsAndControlsContextProvider = ({
  children,
}: StrategyMonitoringStatsAndControlsContextProviderProps) => {
  const { hubUrl } = useDashboardContext();
  const {
    filters: { strategy },
  } = useStrategyMonitoringFiltersContext();

  const [statsUpdatedAt, setStatsUpdatedAt] = useState<Date>(new Date());
  const [positionChangeInProgress, setPositionChangeInProgress] =
    useState<boolean>(false);

  const {
    data: stats,
    isLoading: isLoadingStats,
    refetch: refetchStats,
  } = useQuery<StrategyStats>({
    queryKey: ["strategies", strategy?.app_id, "stats"],
    queryFn: () => fetchStrategyStats({ strategy }),
    initialData: {
      allocation: 0,
      mode: strategyModes.OFF,
      position: {
        avg_fill_price: 0,
        current_allocation: 0,
        current_price: 0,
        expected_allocation: 0,
        market_value: 0,
        pnl_amount: 0,
        pnl_per_unit: 0,
        portfolio_value: 0,
        state: strategyPositionStates.NONE,
        units: 0,
      },
    },
    onSuccess: () => {
      setStatsUpdatedAt(new Date());
      setPositionChangeInProgress(false);
    },
  });

  const allocationMessage = useHubMessage<StrategyAllocationSignalRMessage>({
    hubUrl,
    methodName: "update_allocation",
  });
  useEffect(
    () => handleSignalRMessage(strategy, allocationMessage, refetchStats),
    [strategy, refetchStats, allocationMessage]
  );

  const modeMessage = useHubMessage<StrategyModeSignalRMessage>({
    hubUrl,
    methodName: "update_mode",
  });
  useEffect(
    () => handleSignalRMessage(strategy, modeMessage, refetchStats),
    [strategy, refetchStats, modeMessage]
  );

  const positionMessage = useHubMessage<StrategyPositionSignalRMessage>({
    hubUrl,
    methodName: "update_position",
  });
  useEffect(
    () => handleSignalRMessage(strategy, positionMessage, refetchStats),
    [strategy, refetchStats, positionMessage]
  );

  return (
    <StrategyMonitoringStatsAndControlsContext.Provider
      value={{
        stats,
        statsUpdatedAt,
        isLoadingStats,
        positionChangeInProgress,
        setPositionChangeInProgress,
      }}
    >
      {children}
    </StrategyMonitoringStatsAndControlsContext.Provider>
  );
};
