import { TableCell, TableRow } from "@mui/material";
import { StrategySwitchEvalResult } from "../../types";

export interface StrategyMonitoringSwitchEvalResultRowProps {
  switchResult: StrategySwitchEvalResult;
}
export const StrategyMonitoringSwitchEvalResultRow = ({
  switchResult,
}: StrategyMonitoringSwitchEvalResultRowProps) => (
  <TableRow key={switchResult.switch_tag}>
    <TableCell component="th" scope="row">
      {switchResult.switch_tag} ({switchResult.description})
    </TableCell>
    <TableCell>{switchResult.value ? "YES" : "NO"}</TableCell>
    <TableCell colSpan={2}>{switchResult.overrides.join(", ")}</TableCell>
  </TableRow>
);
