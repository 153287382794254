import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useDashboardContext } from "../../Dashboard/useDashboardContext";
import { generatePerformanceReport } from "../../api";
import { toTitleCase } from "../../helpers";
import {
  PerformanceReportAssessmentInterval,
  PerformanceReportTargetInterval,
  performanceReportAssessmentIntervals,
  performanceReportTargetIntervals,
} from "../../types";
import { usePerformanceReportsContext } from "../usePerformanceReportsContext";
import { AddTradesSourceForm } from "./AddTradesSourceForm";
import { EditTradesSourceForm } from "./EditTradesSourceForm";
import { isSubmittableNumericValue, isValidNumericValue } from "./utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const PerformanceReportsForm = () => {
  const { strategies } = useDashboardContext();
  const {
    params,
    setName,
    isCreatingQCTradesCollection,
    createQCTradesCollection,
    minStartDate,
    setStartDate,
    maxEndDate,
    setEndDate,
    setRiskFreeRate,
    setTargetInterval,
    setManagementFeeRate,
    setManagementFeeAssessmentInterval,
    toggleManagementFeeAssessmentArrears,
    setIncentiveFeeRate,
    setIncentiveFeeAssessmentInterval,
    addTradesSource,
    updateTradesSourceAllocation,
    removeTradesSource,
    benchmarkOptions,
    setBenchmarks,
    setGenerateRequestId,
  } = usePerformanceReportsContext();

  const onChangeName: TextFieldProps["onChange"] = (event) =>
    setName(event.target.value);

  const onChangeStartDate: DateTimePickerProps<Dayjs>["onChange"] = (value) => {
    value && setStartDate(value.toDate());
  };
  const onChangeEndDate: DateTimePickerProps<Dayjs>["onChange"] = (value) => {
    value && setEndDate(value.toDate());
  };

  const onChangeRiskFreeRate: TextFieldProps["onChange"] = (event) => {
    const valueStr: string = event.target.value;
    if (isValidNumericValue(valueStr)) {
      setRiskFreeRate(valueStr);
    } else {
      setRiskFreeRate(params.risk_free_rate);
    }
  };

  const onChangeTargetInteval: TextFieldProps["onChange"] = (event) =>
    setTargetInterval(event.target.value as PerformanceReportTargetInterval);

  const onChangeManagementFeeRate: TextFieldProps["onChange"] = (event) => {
    const valueStr: string = event.target.value;
    if (isValidNumericValue(valueStr)) {
      setManagementFeeRate(valueStr);
    } else {
      setManagementFeeRate(params.risk_free_rate);
    }
  };
  const onChangeManagementFeeAssessmentInteval: TextFieldProps["onChange"] = (
    event
  ) =>
    setManagementFeeAssessmentInterval(
      event.target.value as PerformanceReportAssessmentInterval
    );
  const onChangeManagementFeeAssessmentArrears: SwitchProps["onChange"] = () =>
    toggleManagementFeeAssessmentArrears();

  const onChangeIncentiveFeeRate: TextFieldProps["onChange"] = (event) => {
    const valueStr: string = event.target.value;
    if (isValidNumericValue(valueStr)) {
      setIncentiveFeeRate(valueStr);
    } else {
      setIncentiveFeeRate(params.risk_free_rate);
    }
  };
  const onChangeIncentiveFeeAssessmentInteval: TextFieldProps["onChange"] = (
    event
  ) =>
    setIncentiveFeeAssessmentInterval(
      event.target.value as PerformanceReportAssessmentInterval
    );

  const onChangeBenchmarks: SelectProps["onChange"] = (event) => {
    setBenchmarks(event.target.value as string[]);
  };

  const areParamsValid =
    !!params.start_date &&
    !!params.end_date &&
    isSubmittableNumericValue(params.risk_free_rate) &&
    isSubmittableNumericValue(params.management_fee_rate) &&
    isSubmittableNumericValue(params.incentive_fee_rate) &&
    params.trades_sources.length > 0;
  const onSubmit = () => {
    if (areParamsValid) {
      generatePerformanceReport(params)
        .then((response) => setGenerateRequestId(response.request_id))
        .catch((error) => {
          console.error(error);
          setGenerateRequestId("");
        });
    }
  };

  return (
    <Grid container paddingX={8} paddingY={4} spacing={2}>
      <Grid item xs={3} />
      <Grid item xs={6} justifyContent="center" alignItems="center">
        <Divider textAlign="center">
          <Typography variant="overline">GENERAL</Typography>
        </Divider>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          size="small"
          value={params.name}
          onChange={onChangeName}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TextField
          id="target_interval"
          label="Target Interval"
          variant="outlined"
          size="small"
          select
          value={params.target_interval}
          onChange={onChangeTargetInteval}
          fullWidth
        >
          {Object.keys(performanceReportTargetIntervals).map(
            (targetInterval) => {
              return (
                <MenuItem key={targetInterval} value={targetInterval}>
                  {toTitleCase(targetInterval)}
                </MenuItem>
              );
            }
          )}
        </TextField>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={12} />
      <Grid item xs={3} />
      <Grid item xs={6} justifyContent="center" alignItems="center">
        <Divider textAlign="center">
          <Typography variant="overline">RISK & FEES</Typography>
        </Divider>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TextField
          id="risk_free_rate"
          label="Risk-Free Rate"
          variant="outlined"
          size="small"
          value={params.risk_free_rate}
          onChange={onChangeRiskFreeRate}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={3}>
        <TextField
          id="management_fee_assessment_interval"
          label="Mgmt. Asst. Interval"
          variant="outlined"
          size="small"
          select
          value={params.management_fee_assessment_interval}
          onChange={onChangeManagementFeeAssessmentInteval}
          fullWidth
        >
          {Object.keys(performanceReportAssessmentIntervals).map((interval) => {
            return (
              <MenuItem key={interval} value={interval}>
                {toTitleCase(interval)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="management_fee_rate"
          label="Mgmt. Fee Rate"
          variant="outlined"
          size="small"
          value={params.management_fee_rate}
          onChange={onChangeManagementFeeRate}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <FormGroup>
          <FormControlLabel
            label="Arrears"
            labelPlacement="start"
            control={
              <Switch
                color="primary"
                size="small"
                value={params.management_fee_assessment_arrears}
                onChange={onChangeManagementFeeAssessmentArrears}
              />
            }
            sx={{ margin: 0 }}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={3}>
        <TextField
          id="incentive_fee_assessment_interval"
          label="Inc. Asst. Interval"
          variant="outlined"
          size="small"
          select
          value={params.incentive_fee_assessment_interval}
          onChange={onChangeIncentiveFeeAssessmentInteval}
          fullWidth
        >
          {Object.keys(performanceReportAssessmentIntervals).map((interval) => {
            return (
              <MenuItem key={interval} value={interval}>
                {toTitleCase(interval)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="incentive_fee_rate"
          label="Inc. Fee Rate"
          variant="outlined"
          size="small"
          value={params.incentive_fee_rate}
          onChange={onChangeIncentiveFeeRate}
          fullWidth
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3} />
      <Grid item xs={12} />
      <Grid item xs={3} />
      <Grid item xs={6} justifyContent="center" alignItems="center">
        <Divider textAlign="center">
          <Typography variant="overline">STRATEGIES</Typography>
        </Divider>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6} justifyContent="center" alignItems="center">
        <Button
          component="label"
          color="info"
          variant="outlined"
          fullWidth
          sx={{ height: "100%" }}
          disabled={
            isCreatingQCTradesCollection ||
            (!params.qc_trades_collection && params.trades_sources.length > 0)
          }
        >
          {isCreatingQCTradesCollection
            ? "UPLOADING QC FILE(S) ..."
            : "UPLOAD QC FILE(S)"}
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            multiple
            onChange={(event) => {
              if (event.target.files) {
                createQCTradesCollection(event.target.files);
                event.target.value = "";
              }
            }}
          />
        </Button>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={12} />
      <Grid item xs={3} />
      <Grid item xs={3}>
        <DatePicker
          disableFuture={true}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
            },
          }}
          label={"Start date"}
          minDate={minStartDate}
          maxDate={maxEndDate}
          value={dayjs(params.start_date)}
          onChange={onChangeStartDate}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          disableFuture={true}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
            },
          }}
          label={"End date"}
          minDate={minStartDate}
          maxDate={maxEndDate}
          value={dayjs(params.end_date)}
          onChange={onChangeEndDate}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        {params.trades_sources.map((_, tradesSourceIndex) => (
          <EditTradesSourceForm
            key={`trades_source_${tradesSourceIndex}`}
            params={params}
            tradesSourceIndex={tradesSourceIndex}
            updateTradesSourceAllocation={updateTradesSourceAllocation}
            removeTradesSource={removeTradesSource}
          />
        ))}
        {!params.qc_trades_collection && (
          <AddTradesSourceForm
            strategies={strategies}
            params={params}
            addTradesSource={addTradesSource}
          />
        )}
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <InputLabel id="benchmarks_label">Benchmarks</InputLabel>
          <Select
            labelId="benchmarks_label"
            id="benchmarks"
            multiple
            input={<OutlinedInput label="Benchmarks" />}
            value={params.benchmarks.map(
              (benckmark) => benckmark.instrument_label
            )}
            onChange={onChangeBenchmarks}
            renderValue={(selected) => selected.join(",")}
          >
            {benchmarkOptions.map((benckmarkOption) => {
              return (
                <MenuItem
                  key={benckmarkOption.instrument_label}
                  value={benckmarkOption.instrument_label}
                >
                  {benckmarkOption.instrument_label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          disabled={isCreatingQCTradesCollection || !areParamsValid}
          onClick={onSubmit}
        >
          GENERATE
        </Button>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};
