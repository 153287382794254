import {
  StrategyMonitoringStatsAndControls,
  StrategyMonitoringStatsAndControlsContextProvider,
} from "./StatsAndControls";
import { StrategyMonitoringContent } from "./StrategyMonitoringContent";
import { StrategyMonitoringFilters } from "./StrategyMonitoringFilters";
import { StrategyMonitoringFiltersContextProvider } from "./StrategyMonitoringFiltersContextProvider";

export const StrategyMonitoring = () => {
  return (
    <StrategyMonitoringFiltersContextProvider>
      <StrategyMonitoringFilters />
      <StrategyMonitoringStatsAndControlsContextProvider>
        <StrategyMonitoringStatsAndControls />
        <StrategyMonitoringContent />
      </StrategyMonitoringStatsAndControlsContextProvider>
    </StrategyMonitoringFiltersContextProvider>
  );
};
