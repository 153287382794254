import { createContext, useContext } from "react";
import { noop } from "../../helpers";
import { StrategySignal } from "../../types";

export interface StrategyMonitoringSignalsContextState {
  signals: StrategySignal[];
  isLoadingSignals: boolean;
  seriesVisibility: {
    [selectionKey: string]: { [series: string]: boolean };
  };
  resolveSeriesVisibility: (series: string) => boolean | "legendonly";
  toggleSeriesVisibility: (series: string) => void;
}

export const StrategyMonitoringSignalsContext =
  createContext<StrategyMonitoringSignalsContextState>({
    signals: [],
    isLoadingSignals: false,
    seriesVisibility: {},
    resolveSeriesVisibility: () => true,
    toggleSeriesVisibility: noop,
  });

export const useStrategyMonitoringSignalsContext = () =>
  useContext(StrategyMonitoringSignalsContext);
