import React, { useCallback, useState } from "react";
import { AppConfigContext } from "./useAppConfigContext";
import { PaletteMode } from "@mui/material";

export interface AppConfigContextProviderProps {
  children: React.ReactNode;
}
export const AppConfigContextProvider = ({
  children,
}: AppConfigContextProviderProps) => {
  const [themeMode, setThemeMode] = useState<PaletteMode>("dark");
  const toggleThemeMode = useCallback(
    () => setThemeMode((mode) => (mode === "dark" ? "light" : "dark")),
    [setThemeMode]
  );

  return (
    <AppConfigContext.Provider
      value={{
        themeMode,
        toggleThemeMode,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};
