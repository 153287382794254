import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../Dashboard/useDashboardContext";
import { StrategyEvalEntry, StrategyEvals } from "../types";
import { useHubMessage } from "../useSignalR";
import { useStrategyMonitoringEvalsContext } from "./useStrategyMonitoringEvalsContext";
import { useStrategyMonitoringFiltersContext } from "./useStrategyMonitoringFiltersContext";
import { StrategyMonitoringLiveEvalsContext } from "./useStrategyMonitoringLiveEvalsContext";

export interface StrategyMonitoringLiveEvalsContextProviderProps {
  children: React.ReactNode;
}
export const StrategyMonitoringLiveEvalsContextProvider = ({
  children,
}: StrategyMonitoringLiveEvalsContextProviderProps) => {
  const { hubUrl } = useDashboardContext();
  const {
    filters: { strategy },
    setPeriodIndex,
  } = useStrategyMonitoringFiltersContext();
  const { evals: loadedEvals } = useStrategyMonitoringEvalsContext();

  const [evals, setEvals] = useState<StrategyEvals>(loadedEvals);
  const [evalsUpdatedAt, setEvalsUpdatedAt] = useState<Date>(new Date());

  const evalMessage = useHubMessage<Array<StrategyEvalEntry>>({
    hubUrl,
    methodName: "append_eval",
  });
  useEffect(() => {
    setEvals(loadedEvals);
  }, [loadedEvals, setEvals]);
  useEffect(() => {
    if (evalMessage) {
      for (const entry of evalMessage.data) {
        const accountName = entry.ibkr_account_name;
        const instrumentName = entry.instrument_name;
        const strategyName = entry.strategy_name;
        if (
          accountName !== strategy.account.name ||
          instrumentName !== strategy.instrument.name ||
          strategyName !== strategy.name
        ) {
          console.log(
            `Ignoring entry (${accountName}+${instrumentName}+${strategyName}) because that's not the selected strategy!`
          );
          continue;
        }
        const period = dayjs(entry.period);
        setEvals((prevEvals) => {
          const existingEvalEntryIdx = prevEvals.findIndex(
            (existingEntry) =>
              accountName === existingEntry.ibkr_account_name &&
              instrumentName === existingEntry.instrument_name &&
              strategyName === existingEntry.strategy_name &&
              period.isSame(existingEntry.period)
          );
          const newEvals: StrategyEvals = [...prevEvals];
          if (existingEvalEntryIdx >= 0) {
            newEvals[existingEvalEntryIdx] = entry;
          } else {
            newEvals.push(entry);
          }
          return newEvals.sort((e1, e2) =>
            dayjs(e1.period).diff(dayjs(e2.period))
          );
        });
        setEvalsUpdatedAt(new Date());
      }
    }
  }, [strategy, evalMessage, setEvals, setEvalsUpdatedAt]);

  useEffect(() => {
    if (evals.length > 0) {
      setPeriodIndex(evals.length - 1);
    }
  }, [setPeriodIndex, evals]);

  return (
    <StrategyMonitoringLiveEvalsContext.Provider
      value={{
        evals,
        evalsUpdatedAt,
      }}
    >
      {children}
    </StrategyMonitoringLiveEvalsContext.Provider>
  );
};
