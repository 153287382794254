import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const formatAmount = (value: number): string =>
  value.toLocaleString("en-US", { style: "currency", currency: "USD" });

export const formatPercentage = (
  value: number | undefined,
  precision = 1
): string => (value ? `${value.toFixed(precision)}%` : "N/A");

export const toDateStr = (ts: string) =>
  dayjs.tz(ts, "America/New_York").format("YYYY-MM-DD");
export const toDateTimeStr = (ts: string) =>
  dayjs.tz(ts, "America/New_York").format("YYYY-MM-DD HH:mm");

export const toTitleCase = (sentence: string): string =>
  sentence
    .split(" ")
    .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`)
    .join(" ");
