import { createContext, useContext } from "react";
import { noop } from "../../helpers";
import {
  StrategyStats,
  strategyModes,
  strategyPositionStates,
} from "../../types";

export interface StrategyMonitoringStatsAndControlsContextState {
  stats: StrategyStats;
  statsUpdatedAt: Date;
  isLoadingStats: boolean;
  positionChangeInProgress: boolean;
  setPositionChangeInProgress: (value: boolean) => void;
}

export const StrategyMonitoringStatsAndControlsContext =
  createContext<StrategyMonitoringStatsAndControlsContextState>({
    stats: {
      allocation: 0,
      mode: strategyModes.OFF,
      position: {
        avg_fill_price: 0,
        current_allocation: 0,
        current_price: 0,
        expected_allocation: 0,
        market_value: 0,
        pnl_amount: 0,
        pnl_per_unit: 0,
        portfolio_value: 0,
        state: strategyPositionStates.NONE,
        units: 0,
      },
    },
    statsUpdatedAt: new Date(),
    isLoadingStats: false,
    positionChangeInProgress: false,
    setPositionChangeInProgress: noop,
  });

export const useStrategyMonitoringStatsAndControlsContext = () =>
  useContext(StrategyMonitoringStatsAndControlsContext);
