import { PaletteMode } from "@mui/material";
import { createContext, useContext } from "react";
import { noop } from "./helpers";

export interface AppConfigState {
  themeMode: PaletteMode;
  toggleThemeMode: () => void;
}

export const AppConfigContext = createContext<AppConfigState>({
  themeMode: "dark",
  toggleThemeMode: noop,
});
export const useAppConfigContext = () => useContext(AppConfigContext);
