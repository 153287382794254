import { Strategy, StrategyMode, StrategyPositionState } from "./strategies";

export interface StrategyConditionEvalResult {
  condition_tag: string;
  expression: string;
  evaluation: string;
  value: boolean;
}
export interface StrategyRuleEvalResult {
  rule_tag: string;
  value: boolean;
  conditions: Array<StrategyConditionEvalResult>;
  conditions_count: number;
  satisfied_conditions_count: number;
}
export interface StrategySwitchEvalResult {
  switch_tag: string;
  description: string;
  value: boolean;
  rules: Array<StrategyRuleEvalResult>;
  overrides: Array<string>;
}
export interface StrategyOverrideEvalResult {
  override_tag: string;
  description: string;
  value: boolean;
  switch_values: { [key: string]: boolean };
  rules: Array<StrategyRuleEvalResult>;
}
export interface StrategyEvalResults {
  period: Date;
  value: string;
  switch_results: Array<StrategySwitchEvalResult>;
  override_results: Array<StrategyOverrideEvalResult>;
}

export interface StrategyEvalPositionStats {
  avg_fill_price: number;
  current_allocation?: number;
  current_price: number;
  expected_allocation: number;
  market_value: number;
  pnl_amount: number;
  pnl_per_unit: number;
  portfolio_value?: number;
  units: number;
}

export const signalRMessageTypes = {
  strategy: "strategy",
  performance_report: "performance_report",
} as const;
export type SignalRMessageType = keyof typeof signalRMessageTypes;
interface BaseSignalRMessage {
  type: SignalRMessageType;
}
interface BaseStrategySignalRMessage extends BaseSignalRMessage {
  type: "strategy";
  ibkr_username: string;
  instrument_name: string;
  strategy_name: string;
  target: StrategyUpdateTarget;
  value: string | number;
}
export interface StrategyAllocationSignalRMessage
  extends BaseStrategySignalRMessage {
  target: "allocation";
  value: number;
}
export interface StrategyModeSignalRMessage extends BaseStrategySignalRMessage {
  target: "mode";
  value: StrategyMode;
}
export interface StrategyPositionSignalRMessage
  extends BaseStrategySignalRMessage {
  target: "position";
  value: number;
}
export type StrategySignalRMessage =
  | StrategyAllocationSignalRMessage
  | StrategyModeSignalRMessage
  | StrategyPositionSignalRMessage;

export interface PerformanceReportSignalRMessage extends BaseSignalRMessage {
  type: "performance_report";
  report_id: number;
  request_id: string;
}
export type SignalRMessage =
  | StrategyAllocationSignalRMessage
  | StrategyModeSignalRMessage
  | StrategyPositionSignalRMessage
  | PerformanceReportSignalRMessage;

export interface StrategyEvalEntry {
  strategy_app_key: string;
  ibkr_account_name: string;
  instrument_name: string;
  strategy_name: string;
  period: Date;
  trade_dir?: number;
  signal_dir?: number;
  eval_data: { [key: string]: number | string };
  live_eval_results: { [key: string]: number };
  monitoring_eval_results: StrategyEvalResults;
  position_stats: StrategyEvalPositionStats;
}

export type StrategyEvals = Array<StrategyEvalEntry>;

export const strategyUpdateTargets = {
  ["allocation"]: "allocation",
  ["mode"]: "mode",
  ["position"]: "position",
} as const;
export type StrategyUpdateTarget = keyof typeof strategyUpdateTargets;

interface BaseUpdateStrategyRequestParams {
  strategy: Strategy;
  target: StrategyUpdateTarget;
  value: string | number;
}
interface UpdateStrategyPositionRequestParams
  extends BaseUpdateStrategyRequestParams {
  target: "position";
  value: StrategyPositionState;
}
interface UpdateStrategyModeRequestParams
  extends BaseUpdateStrategyRequestParams {
  target: "mode";
  value: StrategyMode;
}
export type UpdateStrategyRequestParams =
  | UpdateStrategyPositionRequestParams
  | UpdateStrategyModeRequestParams;
