import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import {
  GeneratePerformanceReportRequestParams,
  GeneratePerformanceReportResponse,
  PerformanceReportResult,
  Strategy,
  StrategyEvals,
  UpdateStrategyRequestParams,
} from "../types";
import { baseUrl } from "./common";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface NegotiateSignalRAccessTokenResponse {
  url: string;
  token: string;
}
export const negotiateSignalRAccessToken =
  async (): Promise<NegotiateSignalRAccessTokenResponse> => {
    const response = await fetch(`${baseUrl}/signalr/negotiate`, {
      mode: "cors",
      method: "POST",
    });
    const responseBody =
      (await response.json()) as NegotiateSignalRAccessTokenResponse;
    return responseBody;
  };

export const fetchStrategies = async (): Promise<Strategy[]> => {
  const response = await fetch(`${baseUrl}/strategies`, {
    mode: "cors",
    method: "POST",
  });
  const responseBody = await response.json();
  return responseBody.results;
};

export interface FetchStrategyEvalsParams {
  strategy: Strategy;
  startDate: Date;
  endDate: Date;
}
export const fetchStrategyEvals = async ({
  strategy,
  startDate,
  endDate,
}: FetchStrategyEvalsParams): Promise<StrategyEvals> => {
  const response = await fetch(`${baseUrl}/strategy_evaluations`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ibkr_account_name: strategy.account.name,
      instrument_name: strategy.instrument.name,
      strategy_name: strategy.name,
      start_date: dayjs
        .tz(startDate, "America/New_York")
        .format("YYYY-MM-DDTHH:mm"),
      end_date: dayjs
        .tz(endDate, "America/New_York")
        .format("YYYY-MM-DDTHH:mm"),
    }),
  });
  const { results } = (await response.json()) as { results: StrategyEvals };
  return results;
};

export interface UpdateStrategyRequestResponse {
  message: string;
}
export const updateStrategy = async ({
  strategy,
  target,
  value,
}: UpdateStrategyRequestParams): Promise<UpdateStrategyRequestResponse> => {
  const response = await fetch(
    `${baseUrl}/strategy_apps/${strategy.app_id}/${target}`,
    {
      mode: "cors",
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ target, value }),
    }
  );
  const responseBody = (await response.json()) as UpdateStrategyRequestResponse;
  if (!response.ok) {
    throw new Error(responseBody.message);
  }
  return responseBody;
};

export const generatePerformanceReport = async (
  params: GeneratePerformanceReportRequestParams
): Promise<GeneratePerformanceReportResponse> => {
  const response = await fetch(`${baseUrl}/performance_reports`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      params: {
        ...params,
        start_date: dayjs(params.start_date).format("YYYY-MM-DDTHH:mm"),
        end_date: dayjs(params.end_date).format("YYYY-MM-DDTHH:mm"),
      },
    }),
  });
  const responseBody = await response.json();
  if (!response.ok) {
    throw new Error(responseBody.message);
  }
  return responseBody as GeneratePerformanceReportResponse;
};

export const retrievePerformanceReportResult = async (
  reportId: number
): Promise<PerformanceReportResult> => {
  const response = await fetch(
    `${baseUrl}/performance_reports/${reportId}/result`,
    { mode: "cors" }
  );
  const responseBody = await response.json();
  if (!response.ok) {
    throw new Error(responseBody.message);
  }
  return responseBody as PerformanceReportResult;
};
