import { createContext, useContext } from "react";
import { StrategySignal } from "../../types";

export interface StrategyMonitoringLiveSignalsContextState {
  signals: StrategySignal[];
  signalsUpdatedAt: Date;
}

export const StrategyMonitoringLiveSignalsContext =
  createContext<StrategyMonitoringLiveSignalsContextState>({
    signals: [],
    signalsUpdatedAt: new Date(),
  });

export const useStrategyMonitoringLiveSignalsContext = () =>
  useContext(StrategyMonitoringLiveSignalsContext);
