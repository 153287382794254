import { formatAmount, formatPercentage, toDateStr } from "../../helpers";
import {
  PerformanceReportResult,
  PerformanceReportsResultSeries,
} from "../../types";
import { PerformanceReportsResultTable } from "./Table";

export const columns = [
  "Series",
  "Date",
  "Commissions",
  "Value at Close",
  "Unit Value",
  "Cumm. Unit Value",
  "Cumm. Return",
];

export interface PerformanceReportsResultValueChangesTableProps {
  reportRequestName: string;
  reportRequestTag: string;
  selectedSeries: PerformanceReportsResultSeries[];
  result: PerformanceReportResult;
}
export const PerformanceReportsResultValueChangesTable = ({
  reportRequestName,
  reportRequestTag,
  selectedSeries,
  result,
}: PerformanceReportsResultValueChangesTableProps) => {
  const data: Array<Array<string | number>> = [];

  for (const line of [...selectedSeries.map((series) => result[series])]) {
    for (const entry of line.grouped_market_value_entries) {
      data.push([
        line.label.replaceAll("MOMENTUM", reportRequestName),
        toDateStr(entry.date),
        formatAmount(entry.commissions),
        formatAmount(entry.value_at_close),
        entry.unit_value,
        entry.cumm_unit_value,
        formatPercentage(entry.cumm_return, 2),
      ]);
    }
  }

  return (
    <PerformanceReportsResultTable
      columns={columns}
      data={data}
      csvFileName={`${reportRequestTag} - Market Value Changes.csv`}
    />
  );
};
