import { TableCell, TableRow } from "@mui/material";
import { StrategySignalSwitchOverrideEntry } from "../../../types";

export interface StrategyMonitoringSignalTableSwitchOverrideRowProps {
  entry: StrategySignalSwitchOverrideEntry;
}
export const StrategyMonitoringSignalTableSwitchOverrideRow = ({
  entry,
}: StrategyMonitoringSignalTableSwitchOverrideRowProps) => {
  return (
    <TableRow key={entry.label}>
      <TableCell component="th" scope="row">
        {entry.label}
        {entry.description ? ` (${entry.description})` : null}
      </TableCell>
      <TableCell>{entry.value ? "YES" : "NO"}</TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
