import {
  StrategyMonitoringSignalSeriesConfig,
  StrategyMonitoringSignalSeriesOrder,
  resolveTextAndXAndY,
  resolveTextAndXAndYForMaxMin,
} from "../SignalSeriesConfig";
import colors from "../colors";

export const config: StrategyMonitoringSignalSeriesConfig = {
  ALMOND10: {
    label: "ALMOND10",
    color: colors.almond,
    resolveTextAndXAndY,
  },
  BEECH10: {
    label: "BEECH10",
    color: colors.beech,
    resolveTextAndXAndY,
  },
  BIRCH10: {
    label: "BIRCH10",
    color: colors.birch,
    resolveTextAndXAndY,
  },
  BLACK: {
    label: "BLACK2",
    color: colors.black,
    resolveTextAndXAndY,
  },
  BLACK10: {
    label: "BLACK10",
    color: colors.black,
    resolveTextAndXAndY,
  },
  ["2MIN_XXXLT"]: {
    label: "BLUE2",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  BLUE10: {
    label: "BLUE10",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  BRONZE10: {
    label: "BRONZE10",
    color: colors.bronze,
    resolveTextAndXAndY,
  },
  BROWN: {
    label: "BROWN2",
    color: colors.brown,
    resolveTextAndXAndY,
  },
  BROWN10: {
    label: "BROWN10",
    color: colors.brown,
    resolveTextAndXAndY,
  },
  COPPER10: {
    label: "COPPER10",
    color: colors.copper,
    resolveTextAndXAndY,
  },
  GOLD: {
    label: "GOLD2",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  GOLD10: {
    label: "GOLD10",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  GREEN: {
    label: "GREEN2",
    color: colors.green,
    resolveTextAndXAndY,
  },
  GREEN10: {
    label: "GREEN10",
    color: colors.green,
    resolveTextAndXAndY,
  },
  IRON10: {
    label: "IRON10",
    color: colors.iron,
    resolveTextAndXAndY,
  },
  MAGENTA10: {
    label: "MAGENTA10",
    color: colors.magenta,
    resolveTextAndXAndY,
  },
  MDLMAX10: {
    label: "MDLMAX10",
    color: colors.mdlmax,
    size: 16,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  MDLMIN10: {
    label: "MDLMIN10",
    color: colors.mdlmin,
    size: 16,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  OPAL10: {
    label: "OPAL10",
    color: colors.opal,
    resolveTextAndXAndY,
  },
  OPAL20_10: {
    label: "OPAL20_10",
    color: colors.opal,
    resolveTextAndXAndY,
  },
  ORANGE10: {
    label: "ORANGE10",
    color: colors.orange,
    resolveTextAndXAndY,
  },
  PINK10: {
    label: "PINK10",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  PINK: {
    label: "PINK2",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  PRICE: {
    label: "PRICE",
    color: colors.field,
    resolveTextAndXAndY,
  },
  PRICE_L_10: {
    label: "PRICE_L_10",
    color: colors.field,
    resolveTextAndXAndY,
  },
  PRICE_N: {
    label: "PRICE_N",
    color: colors.field,
    resolveTextAndXAndY,
  },
  PRICE_N_10: {
    label: "PRICE_N_10",
    color: colors.field,
    resolveTextAndXAndY,
  },
  RED: {
    label: "RED2",
    color: colors.red,
    resolveTextAndXAndY,
  },
  RED10: {
    label: "RED10",
    color: colors.red,
    resolveTextAndXAndY,
  },
  SILVER: {
    label: "SILVER2",
    color: colors.silver,
    resolveTextAndXAndY,
  },
  SILVER10: {
    label: "SILVER10",
    color: colors.silver,
    resolveTextAndXAndY,
  },
  SUBMAX: {
    label: "SUBMAX2",
    color: colors.submax,
    size: 10,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  SUBMAX10: {
    label: "SUBMAX10",
    color: colors.submax,
    size: 10,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  SUBMIN: {
    label: "SUBMIN2",
    color: colors.submin,
    size: 10,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  SUBMIN10: {
    label: "SUBMIN10",
    color: colors.submin,
    size: 10,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  TAN10: {
    label: "TAN10",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  TAN: {
    label: "TAN2",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  ["2MIN_XLT"]: {
    label: "WHITE2",
    color: colors.white,
    resolveTextAndXAndY,
  },
  WHITE10: {
    label: "WHITE10",
    color: colors.white,
    resolveTextAndXAndY,
  },
  ["TRADE DIR"]: {
    label: "TRADEDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
  ["POSITION DIR"]: {
    label: "POSDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
};

export const order: StrategyMonitoringSignalSeriesOrder = [
  "PRICE",
  "GOLD2", // EMA_SST
  "BROWN2", // EMA_ST
  "TAN2", // INT
  "PINK2", // LT
  "WHITE2", // XLT
  "GREEN2", // XXLT
  "BLUE2", // XXXLT
  "PRICE_N",
  "PRICE_L_10",
  "PRICE_N_10",
  "GOLD10", // EMA_SST
  "BROWN10", // EMA_ST
  "TAN10", // INT
  "PINK10", // LT
  "WHITE10", // XLT
  "GREEN10", // XXLT
  "BLUE10", // XXXLT
  "MDLMAX10",
  "MDLMIN10",
  "SUBMAX2",
  "SUBMAX10",
  "SUBMIN2",
  "SUBMIN10",
  "TRADEDIR",
  "POSDIR",
];
