import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { StrategyRuleEvalResult } from "../../types";
import { StrategyMonitoringConditionEvalResultTable } from "./StrategyMonitoringConditionEvalResultTable";

export interface StrategyMonitoringRuleEvalResultRowProps {
  parentTag: string;
  ruleResult: StrategyRuleEvalResult;
}
export const StrategyMonitoringRuleEvalResultRow = ({
  parentTag,
  ruleResult,
}: StrategyMonitoringRuleEvalResultRowProps) => {
  const [expandConditionResults, setExpandConditionResults] = useState(false);
  if (ruleResult.conditions_count === 0) {
    return null;
  }
  return (
    <>
      <TableRow
        key={`${parentTag}+${ruleResult.rule_tag}`}
        style={{ border: "unset" }}
      >
        <TableCell
          component="th"
          scope="row"
        >{`Rule ${ruleResult.rule_tag}`}</TableCell>
        <TableCell>{ruleResult.value ? "YES" : "NO"}</TableCell>
        <TableCell>{`${ruleResult.satisfied_conditions_count}/${ruleResult.conditions_count}`}</TableCell>
        <TableCell size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpandConditionResults(!expandConditionResults)}
          >
            {expandConditionResults ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <StrategyMonitoringConditionEvalResultTable
        expandConditionResults={expandConditionResults}
        ruleTag={ruleResult.rule_tag}
        conditionResults={ruleResult.conditions}
      />
    </>
  );
};
