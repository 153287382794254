import { DashboardContextProvider } from "./DashboardContextProvider";
import { DashboardAppBar } from "./DashboardAppBar";
import { DashboardContent } from "./DashboardContent";

export const Dashboard = () => {
  return (
    <DashboardContextProvider>
      <DashboardAppBar />
      <DashboardContent />
    </DashboardContextProvider>
  );
};
