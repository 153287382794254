import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CSVLink } from "react-csv";
import {
  PerformanceReportResult,
  PerformanceReportsResultSeries,
  performanceReportLineTypes,
} from "../../types";

export interface PerformanceReportsResultRawDataTableProps {
  reportRequestTag: string;
  selectedSeries: PerformanceReportsResultSeries[];
  result: PerformanceReportResult;
}
export const PerformanceReportsResultRawDataTable = ({
  reportRequestTag,
  selectedSeries,
  result,
}: PerformanceReportsResultRawDataTableProps) => {
  return (
    <Box paddingX={8} paddingY={4}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Strategy</TableCell>
              <TableCell>Raw Trade Data</TableCell>
              <TableCell>Raw Market Value Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              ...selectedSeries.map((series) => result[series]),
              result.benchmark,
            ]
              .find((line) => line.type === performanceReportLineTypes.momentum)
              ?.config.map((config) => {
                const strategyKey = `${config.instrument_name}`;
                return (
                  <TableRow
                    key={`strategy_${config.instrument_id}`}
                    style={{ border: "unset" }}
                  >
                    <TableCell>{strategyKey}</TableCell>
                    <TableCell>
                      <CSVLink
                        data={config.trade_entries}
                        filename={`${reportRequestTag} - ${strategyKey} - Raw Trade Data.csv`}
                        target="_blank"
                      >
                        Download CSV
                      </CSVLink>
                    </TableCell>
                    <TableCell>
                      <CSVLink
                        data={config.market_value_entries}
                        filename={`${reportRequestTag} - ${strategyKey} - Raw Market Value Data.csv`}
                        target="_blank"
                      >
                        Download CSV
                      </CSVLink>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
