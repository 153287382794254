import { createContext, useContext } from "react";
import { ApiAuthDetails } from "./types";

export interface ApiAuthDetailsContextState {
  apiAuthDetails: ApiAuthDetails | null;
}

export const ApiAuthDetailsContext = createContext<ApiAuthDetailsContextState>({
  apiAuthDetails: null,
});

export const useApiAuthDetailsContext = () => useContext(ApiAuthDetailsContext);
