import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toDateTimeStr } from "../../../helpers";
import { useStrategyMonitoringFiltersContext } from "../../useStrategyMonitoringFiltersContext";
import { useStrategyMonitoringLiveSignalsContext } from "../useLiveSignalsContext";
import { StrategyMonitoringSignalTableSwitchOverrideRow } from "./SwitchOverrideRow";
import { StrategyMonitoringSignalTableSwitchRow } from "./SwitchRow";
import { StrategyMonitoringSignalTableSwitchRuleRow } from "./SwitchRuleRow";

export const StrategyMonitoringSignalTable = () => {
  const { periodIndex } = useStrategyMonitoringFiltersContext();
  const { signals } = useStrategyMonitoringLiveSignalsContext();

  if (!signals || periodIndex === null) {
    return null;
  }

  const signal = signals[periodIndex];
  if (!signal) {
    return null;
  }

  const tableRows = [];
  for (const entry of signal.switch_entries) {
    tableRows.push(
      <StrategyMonitoringSignalTableSwitchRow key={entry.label} entry={entry} />
    );
    for (const ruleEntry of entry.rule_entries) {
      tableRows.push(
        <StrategyMonitoringSignalTableSwitchRuleRow
          key={`${entry.label}:${ruleEntry.label}`}
          entry={ruleEntry}
        />
      );
    }
  }
  for (const entry of signal.switch_override_entries) {
    tableRows.push(
      <StrategyMonitoringSignalTableSwitchOverrideRow
        key={entry.label}
        entry={entry}
      />
    );
    for (const rule_entry of Object.values(entry.rule_entries)) {
      tableRows.push(
        <StrategyMonitoringSignalTableSwitchRuleRow
          key={`${entry.label}:${rule_entry.label}`}
          entry={rule_entry}
        />
      );
    }
  }

  return (
    <Box paddingX={8} paddingY={4}>
      <Typography variant="subtitle1">
        Period: {toDateTimeStr(signal.period)}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Condition</TableCell>
              <TableCell>Eval</TableCell>
              <TableCell>Satisfied</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
