import { PerformanceReportBenchmark, QCTradesCollection } from "../types";
import { baseUrl } from "./common";

export const fetchBenchmarkOptions = async (): Promise<
  PerformanceReportBenchmark[]
> => {
  const response = await fetch(
    `${baseUrl}/performance_reports/benchmark_options`,
    {
      method: "GET",
      headers: { Accept: "application/json" },
    }
  );
  const result = (await response.json()) as PerformanceReportBenchmark[];
  return result;
};

export const createQCTradesCollection = async (
  files: FileList
): Promise<QCTradesCollection> => {
  const formData = new FormData();

  Array.from(files).forEach((file) => formData.append("file", file));

  const response = await fetch(
    `${baseUrl}/performance_reports/qc_trades_collections`,
    {
      method: "POST",
      headers: { Accept: "application/json" },
      body: formData,
    }
  );
  const result = (await response.json()) as QCTradesCollection;
  return result;
};
