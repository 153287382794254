import { formatPercentage } from "../../../helpers";
import {
  Strategy,
  StrategyPositionState,
  UpdateStrategyRequestParams,
  strategyUpdateTargets,
} from "../../../types";

export interface StrategyActionConfirmationDetails {
  title: string;
  message: string;
  requestParams: UpdateStrategyRequestParams;
}

export type StrategyActionConfirmationDetailsResolver = (params: {
  strategy: Strategy;
  allocation: number;
  positionState: StrategyPositionState;
  positionUnits: number;
}) => StrategyActionConfirmationDetails;

export const strategyActionIntents = {
  ["LONG"]: "LONG",
  ["CLOSE"]: "CLOSE",
  ["SHORT"]: "SHORT",
  ["PAUSE"]: "PAUSE",
  ["RESUME"]: "RESUME",
  ["STOP"]: "STOP",
  ["START"]: "START",
} as const;

export type StrategyActionIntent = keyof typeof strategyActionIntents;

const changePositionTitle = "Change Position";
const longResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
  allocation,
  positionState,
  positionUnits,
}) => {
  let message = `Are you sure you want to go LONG for ${strategy.instrument.name}?\n`;
  if (positionState === "SHORT") {
    message += `Trader will cover the SHORT position (${positionUnits} units) and `;
  } else {
    message += "Trader will ";
  }
  message += `allocate ${formatPercentage(allocation)} of buying power for ${
    strategy.instrument.name
  }.`;
  return {
    title: changePositionTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.position,
      value: "LONG",
    },
  };
};
const closeResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
  positionState,
  positionUnits,
}) => {
  let message = `Are you sure you want to CLOSE ${strategy.instrument.name}'s position?\n`;
  if (positionState === "LONG") {
    message += `Trader will SELL ${positionUnits} units.`;
  } else if (positionState === "SHORT") {
    message += `Trader will BUY ${Math.abs(positionUnits)} units.`;
  }
  return {
    title: changePositionTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.position,
      value: "NONE",
    },
  };
};
const shortResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
  allocation,
  positionState,
  positionUnits,
}) => {
  let message = `Are you sure you want to go SHORT for ${strategy.instrument.name}?\n`;
  if (positionState === "LONG") {
    message += `Trader will cover the LONG position (${positionUnits} units) and `;
  } else {
    message += "Trader will ";
  }
  message += `allocate ${formatPercentage(allocation)} of buying power for ${
    strategy.instrument.name
  }.`;
  return {
    title: changePositionTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.position,
      value: "SHORT",
    },
  };
};

const changeModeTitle = "Change Mode";
const pauseResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
  positionState,
  positionUnits,
}) => {
  let message = `Are you sure you want to PAUSE trading for ${strategy.instrument.name}?\n`;
  if (positionState === "LONG" || positionState === "SHORT") {
    message += ` Trader won't CLOSE your ${positionState} position (${positionUnits} units).`;
  }
  return {
    title: changeModeTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.mode,
      value: "EVAL",
    },
  };
};
const resumeResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
}) => {
  const message = `Are you sure you want to RESUME trading for ${strategy.instrument.name}?`;
  return {
    title: changeModeTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.mode,
      value: "TRADE",
    },
  };
};
const stopResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
  positionState,
  positionUnits,
}) => {
  let message = `Are you sure you want to STOP trading for ${strategy.instrument.name}?`;
  if (positionState === "LONG" || positionState === "SHORT") {
    message += ` Trader will immediately CLOSE your ${
      positionState === "LONG" ? "LONG" : "SHORT"
    } position (${positionUnits} units).`;
  }
  return {
    title: changeModeTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.mode,
      value: "OFF",
    },
  };
};
const startResolver: StrategyActionConfirmationDetailsResolver = ({
  strategy,
}) => {
  const message = `Are you sure you want to START trading for ${strategy.instrument.name}?`;
  return {
    title: changeModeTitle,
    message,
    requestParams: {
      strategy,
      target: strategyUpdateTargets.mode,
      value: "TRADE",
    },
  };
};

export const strategyActionConfirmationDetailsResolverByIntent = {
  [strategyActionIntents.LONG]: longResolver,
  [strategyActionIntents.CLOSE]: closeResolver,
  [strategyActionIntents.SHORT]: shortResolver,
  [strategyActionIntents.PAUSE]: pauseResolver,
  [strategyActionIntents.RESUME]: resumeResolver,
  [strategyActionIntents.STOP]: stopResolver,
  [strategyActionIntents.START]: startResolver,
} as const;
