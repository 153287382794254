import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
} from "@mui/material";
import {
  PerformanceReportResult,
  PerformanceReportsResultSeries,
  performanceReportsResultSeries,
} from "../../types";

export interface PerformanceReportsResultSeriesChooserProps {
  reportRequestName: string;
  selectedSeries: PerformanceReportsResultSeries[];
  setSelectedSeries: (series: PerformanceReportsResultSeries[]) => void;
  selectedBenchmarks: string[];
  setSelectedBenchmarks: (series: string[]) => void;
  result: PerformanceReportResult;
}

export const PerformanceReportsResultSeriesChooser = ({
  reportRequestName,
  selectedSeries,
  setSelectedSeries,
  selectedBenchmarks,
  setSelectedBenchmarks,
  result,
}: PerformanceReportsResultSeriesChooserProps) => {
  const momentumValue = selectedSeries.length > 1 ? "both" : selectedSeries[0];
  const handleMomentumChange: ToggleButtonProps["onChange"] = (_, newValue) => {
    if (!newValue) {
      return;
    }
    const newSelectedSeries: PerformanceReportsResultSeries[] = [];
    if (newValue === "both") {
      newSelectedSeries.push(performanceReportsResultSeries.momentum);
      newSelectedSeries.push(performanceReportsResultSeries.net_of_fees);
    } else {
      newSelectedSeries.push(newValue as PerformanceReportsResultSeries);
    }
    setSelectedSeries(newSelectedSeries);
  };
  const handleBenchmarksChange: ToggleButtonProps["onChange"] = (
    _,
    newValues
  ) => {
    setSelectedBenchmarks(newValues);
  };
  return (
    <Stack paddingX={8} paddingY={4} rowGap={2}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <ToggleButtonGroup
          color="primary"
          value={momentumValue}
          exclusive
          onChange={handleMomentumChange}
        >
          <ToggleButton value={performanceReportsResultSeries.momentum}>
            {reportRequestName}
          </ToggleButton>
          <ToggleButton value="both">
            {reportRequestName} & NET OF FEES
          </ToggleButton>
          <ToggleButton value={performanceReportsResultSeries.net_of_fees}>
            NET OF FEES
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <ToggleButtonGroup
          color="primary"
          value={selectedBenchmarks}
          onChange={handleBenchmarksChange}
        >
          {[{ label: reportRequestName }, ...result.benchmarks].map(
            (benchmark) => (
              <ToggleButton key={benchmark.label} value={benchmark.label}>
                {benchmark.label}
              </ToggleButton>
            )
          )}
        </ToggleButtonGroup>
      </Box>
    </Stack>
  );
};
