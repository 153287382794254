import { formatAmount, toDateStr } from "../../helpers";
import {
  PerformanceReportResult,
  PerformanceReportResultFeeEntry,
} from "../../types";
import { PerformanceReportsResultTable } from "./Table";

const buildColumns = (managementFeeAssessmentArrears: boolean): string[] => {
  if (managementFeeAssessmentArrears) {
    return [
      "Date",
      "BOP High Water Mark",
      "BOP Gross Value",
      "BOP Net Value",
      "PnL",
      "EOP Gross Value",
      "Mgmt. Fee",
      "Profit",
      "Inc. Fee",
      "EOP High Water Mark",
      "EOP Net Value",
    ];
  }
  return [
    "Date",
    "BOP High Water Mark",
    "BOP Gross Value",
    "Mgmt. Fee",
    "BOP Net Value",
    "PnL",
    "EOP Gross Value",
    "Profit",
    "Inc. Fee",
    "EOP High Water Mark",
    "EOP Net Value",
  ];
};
const buildRow = (
  managementFeeAssessmentArrears: boolean,
  entry: PerformanceReportResultFeeEntry
): string[] => {
  if (managementFeeAssessmentArrears) {
    return [
      toDateStr(entry.date),
      formatAmount(entry.bop_high_water_mark),
      formatAmount(entry.bop_gross_value),
      formatAmount(entry.bop_net_value),
      formatAmount(entry.pnl),
      formatAmount(entry.eop_gross_value),
      formatAmount(entry.management_fee),
      formatAmount(entry.profit),
      formatAmount(entry.incentive_fee),
      formatAmount(entry.eop_high_water_mark),
      formatAmount(entry.eop_net_value),
    ];
  }
  return [
    toDateStr(entry.date),
    formatAmount(entry.bop_high_water_mark),
    formatAmount(entry.bop_gross_value),
    formatAmount(entry.management_fee),
    formatAmount(entry.bop_net_value),
    formatAmount(entry.pnl),
    formatAmount(entry.eop_gross_value),
    formatAmount(entry.profit),
    formatAmount(entry.incentive_fee),
    formatAmount(entry.eop_high_water_mark),
    formatAmount(entry.eop_net_value),
  ];
};
const buildFooter = (
  managementFeeAssessmentArrears: boolean,
  managementFees: number,
  incentiveFees: number
): Array<string | null> => {
  if (managementFeeAssessmentArrears) {
    return [
      null,
      null,
      null,
      null,
      null,
      null,
      formatAmount(managementFees),
      null,
      formatAmount(incentiveFees),
      null,
      null,
    ];
  }
  return [
    null,
    null,
    null,
    formatAmount(managementFees),
    null,
    null,
    null,
    null,
    formatAmount(incentiveFees),
    null,
    null,
  ];
};

export interface PerformanceReportsResultFeesTableProps {
  managementFeeAssessmentArrears: boolean;
  reportRequestTag: string;
  result: PerformanceReportResult;
}
export const PerformanceReportsResultFeesTable = ({
  managementFeeAssessmentArrears,
  reportRequestTag,
  result,
}: PerformanceReportsResultFeesTableProps) => {
  const data: Array<Array<string | number>> = [];

  let managementFees = 0;
  let incentiveFees = 0;

  for (const entry of result.grouped_fee_entries) {
    if (entry.assessed_management_fee || entry.assessed_incentive_fee) {
      managementFees += entry.management_fee;
      incentiveFees += entry.incentive_fee;
      data.push(buildRow(managementFeeAssessmentArrears, entry));
    }
  }

  return (
    <PerformanceReportsResultTable
      columns={buildColumns(managementFeeAssessmentArrears)}
      data={data}
      footer={buildFooter(
        managementFeeAssessmentArrears,
        managementFees,
        incentiveFees
      )}
      csvFileName={`${reportRequestTag} - Management and Incentive Fees.csv`}
    />
  );
};
