import { Account, Instrument, Periodicity } from "./shared";

export interface StrategySeriesItem {
  name: string;
  label: string;
  mode: string;
}

export interface Strategy {
  app_id: number;
  account: Account;
  instrument: Instrument;
  periodicity: Periodicity;
  name: string;
  series: {
    price: StrategySeriesItem[];
    spread: StrategySeriesItem[];
    zscore: StrategySeriesItem[];
    slope: StrategySeriesItem[];
  };
}

export const strategyModes = {
  ["EVAL"]: "EVAL",
  ["TRADE"]: "TRADE",
  ["OFF"]: "OFF",
} as const;
export type StrategyMode = keyof typeof strategyModes;

export const strategyPositionStates = {
  ["LONG"]: "LONG",
  ["SHORT"]: "SHORT",
  ["NONE"]: "NONE",
} as const;
export type StrategyPositionState = keyof typeof strategyPositionStates;

export interface StrategyPosition {
  avg_fill_price: number;
  current_allocation: number;
  current_price: number;
  expected_allocation: number;
  market_value: number;
  pnl_amount: number;
  pnl_per_unit: number;
  portfolio_value: number;
  state: StrategyPositionState;
  units: number;
}

export interface StrategyStats {
  allocation: number;
  mode: StrategyMode;
  position: StrategyPosition;
}
