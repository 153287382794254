import { PlotData } from "plotly.js";
import { toDateTimeStr } from "../../../helpers";
import { StrategySignal, StrategySignalSeriesEntry } from "../../../types";

export type ResolveTextAndXAndYFunc = (
  entry: StrategySignalSeriesEntry,
  signal: StrategySignal,
  signalIdx: number,
  signalsCount: number
) => [string, string | number | null, number | null];

export const resolveTextAndXAndY: ResolveTextAndXAndYFunc = (
  entry,
  signal,
  signalIdx
) => {
  return [toDateTimeStr(signal.period), signalIdx, entry.value];
};
export const resolveTextAndXAndYForMaxMin: ResolveTextAndXAndYFunc = (
  entry,
  signal,
  signalIdx,
  signalsCount
) => {
  if (signalIdx < signalsCount - 1) {
    return ["", null, null];
  }
  return resolveTextAndXAndY(entry, signal, signalIdx, signalsCount);
};

export interface StrategyMonitoringSignalSeriesConfigEntry {
  label: string;
  color: string;
  size?: number;
  resolveTextAndXAndY: ResolveTextAndXAndYFunc;
  overrides?: Partial<PlotData>;
}
export type StrategyMonitoringSignalSeriesConfig = {
  [series: string]: StrategyMonitoringSignalSeriesConfigEntry;
};
export type StrategyMonitoringSignalSeriesOrder = Array<string>;
