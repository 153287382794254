import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Dashboard } from "./Dashboard";
import { ApiAuthDetailsContextProvider } from "./ApiAuthDetails";
import { appTitle } from "./constants";
import { useAppConfigContext } from "./useAppConfigContext";
import { AppConfigContextProvider } from "./AppConfigContextProvider";

const AppThemed = ({ children }: { children: React.ReactNode }) => {
  const { themeMode } = useAppConfigContext();

  const theme = createTheme({
    palette: {
      mode: themeMode,
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export const App = () => {
  const queryClient = new QueryClient();

  useEffect(() => {
    document.title = appTitle;
  }, []);

  return (
    <ApiAuthDetailsContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <AppConfigContextProvider>
            <AppThemed>
              <CssBaseline />
              <Dashboard />
            </AppThemed>
          </AppConfigContextProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ApiAuthDetailsContextProvider>
  );
};
