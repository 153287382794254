import { Box, Grid, Typography } from "@mui/material";
import { formatAmount, formatPercentage } from "../../helpers";
import { useStrategyMonitoringStatsAndControlsContext } from "./useStatsAndControlsContext";

export const PositionStats = () => {
  const {
    stats: { allocation, position },
  } = useStrategyMonitoringStatsAndControlsContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            Position:
          </Typography>
          <Typography marginLeft={3} variant="body2" component="span">
            {position.units}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            Price:
          </Typography>
          <Typography marginLeft={3} variant="body2" component="span">
            {position?.current_price
              ? formatAmount(position.current_price)
              : "N/A"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            Market Value:
          </Typography>
          <Typography variant="body2" component="span">
            {position?.market_value
              ? formatAmount(position.market_value)
              : "N/A"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            Avg. Fill Price:
          </Typography>
          <Typography marginLeft={3} variant="body2" component="span">
            {position?.avg_fill_price
              ? formatAmount(position.avg_fill_price)
              : "N/A"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            P\L:
          </Typography>
          <Typography marginLeft={3} variant="body2" component="span">
            {position?.pnl_amount ? formatAmount(position.pnl_amount) : "N/A"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            Expected Alloc.:
          </Typography>
          <Typography marginLeft={3} variant="body2" component="span">
            {formatPercentage(allocation)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" component="span">
            Current Alloc.:
          </Typography>
          <Typography marginLeft={3} variant="body2" component="span">
            {position?.current_allocation
              ? formatPercentage(position.current_allocation)
              : "N/A"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
