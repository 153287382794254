export const performanceReportsTradesSourceType = {
  ["IBKR"]: "IBKR",
  ["QC"]: "QC",
} as const;
export type PerformanceReportsTradesSourceType =
  keyof typeof performanceReportsTradesSourceType;
export interface PerformanceReportTradesSource {
  source_type: PerformanceReportsTradesSourceType;
  source_id: number;
  source_label: string;
  instrument_id: number;
  instrument_label: string;
  allocation: number;
}

export interface PerformanceReportBenchmark {
  instrument_id: number;
  instrument_label: string;
}

export interface QCTradesCollection {
  id: number;
  start_date: string;
  end_date: string;
  trades_sources: Array<PerformanceReportTradesSource>;
}

export interface CreateQCTradesCollectionParams {
  files: FileList;
}

export const performanceReportTargetIntervals = {
  daily: "daily",
  monthly: "monthly",
  quarterly: "quarterly",
} as const;
export type PerformanceReportTargetInterval =
  keyof typeof performanceReportTargetIntervals;
export const performanceReportAssessmentIntervals = {
  monthly: "monthly",
  quarterly: "quarterly",
  yearly: "yearly",
} as const;
export type PerformanceReportAssessmentInterval =
  keyof typeof performanceReportAssessmentIntervals;
export interface GeneratePerformanceReportRequestParams {
  name: string;
  qc_trades_collection: QCTradesCollection | null;
  start_date: Date;
  end_date: Date;
  risk_free_rate: string;
  target_interval: PerformanceReportTargetInterval;
  management_fee_rate: string;
  management_fee_assessment_interval: PerformanceReportAssessmentInterval;
  management_fee_assessment_arrears: boolean;
  incentive_fee_rate: string;
  incentive_fee_assessment_interval: PerformanceReportAssessmentInterval;
  trades_sources: PerformanceReportTradesSource[];
  benchmarks: PerformanceReportBenchmark[];
}
export interface GeneratePerformanceReportResponse {
  request_id: string;
}

export const performanceReportsResultSeries = {
  ["momentum"]: "momentum",
  ["net_of_fees"]: "net_of_fees",
} as const;
export type PerformanceReportsResultSeries =
  keyof typeof performanceReportsResultSeries;

export const performanceReportLineTypes = {
  momentum: "momentum",
  net_of_fees: "net_of_fees",
  benchmark: "benchmark",
} as const;
export type PerformanceReportLineType = keyof typeof performanceReportLineTypes;
export interface PerformanceReportResultLine {
  type: PerformanceReportLineType;
  label: string;
  config: Array<{
    instrument_id: number;
    instrument_name: string;
    allocation: number;
    trade_entries: Array<{ [key: string]: string }>;
    market_value_entries: Array<{ [key: string]: string }>;
  }>;
  allocation: number;
  grouped_market_value_entries: Array<{
    date: string;
    value_at_close: number;
    commissions: number;
    unit_value: number;
    cumm_unit_value: number;
    cumm_return: number;
  }>;
  metrics: {
    standard_deviation: number;
    variance: number;
    peak_value: number;
    trough_value: number;
    max_drawdown: number;
    sharpe_ratio: number;
    sortino_ratio: number;
    semi_deviation: number;
  };
  returns_summary: {
    three_months: number;
    six_months: number;
    one_year: number;
    year_to_date: number;
    inception_to_date: number;
  };
}
export interface PerformanceReportResultFeeEntry {
  date: string;
  bop_high_water_mark: number;
  bop_gross_value: number;
  assessed_management_fee: boolean;
  management_fee: number;
  bop_net_value: number;
  pnl: number;
  eop_gross_value: number;
  profit: number;
  assessed_incentive_fee: boolean;
  incentive_fee: number;
  eop_high_water_mark: number;
  eop_net_value: number;
}
export interface PerformanceReportResult {
  performance_report_id: number;
  momentum: PerformanceReportResultLine;
  management_fee_rates: {
    simple: number;
    yearly: number;
    quarterly: number;
    monthly: number;
  };
  grouped_fee_entries: PerformanceReportResultFeeEntry[];
  net_of_fees: PerformanceReportResultLine;
  benchmark: PerformanceReportResultLine;
  benchmarks: PerformanceReportResultLine[];
}
