import { formatAmount, formatPercentage } from "../../helpers";
import {
  PerformanceReportResult,
  PerformanceReportsResultSeries,
} from "../../types";
import { PerformanceReportsResultTable } from "./Table";

const metricLabelsByMetricKey: { [key: string]: string } = {
  peak_value: "Peak Value",
  trough_value: "Trough Value",
  max_drawdown: "Max. Drawdown",
  sharpe_ratio: "Sharpe Ratio",
  sortino_ratio: "Sortino Ratio",
  var: "Variance",
  std: "Std. Deviation (Risk)",
  value_at_risk: "VaR (95% conf.)",
  semi_deviation: "Semi-Deviation",
} as const;
const formatMetricFuncsByMetricKey: {
  [key: string]: (value: number) => string;
} = {
  peak_value: (value) => formatAmount(value),
  trough_value: (value) => formatAmount(value),
  max_drawdown: (value) => formatPercentage(value, 2),
  sharpe_ratio: (value) => `${value.toFixed(2)}`,
  sortino_ratio: (value) => `${value.toFixed(2)}`,
  var: (value) => `${value.toFixed(6)}`,
  std: (value) => formatPercentage(value, 2),
  value_at_risk: (value) => formatPercentage(value, 2),
  semi_deviation: (value) => formatPercentage(value, 2),
};
const metricsInOrder = [
  "peak_value",
  "trough_value",
  "max_drawdown",
  "sharpe_ratio",
  "sortino_ratio",
  "var",
  "std",
  "value_at_risk",
  "semi_deviation",
];

export interface PerformanceReportsResultMetricsTableProps {
  reportRequestName: string;
  reportRequestTag: string;
  selectedSeries: PerformanceReportsResultSeries[];
  selectedBenchmarks: string[];
  result: PerformanceReportResult;
}
export const PerformanceReportsResultMetricsTable = ({
  reportRequestName,
  reportRequestTag,
  selectedSeries,
  selectedBenchmarks,
  result,
}: PerformanceReportsResultMetricsTableProps) => {
  const data: Array<Array<string | number>> = [];

  const lineKeys: Array<string> = [];
  const lineLabels: Array<string> = [];
  const metricsData: {
    [metricKey: string]: { [lineKey: string]: number };
  } = {};

  let lines = [...selectedSeries.map((series) => result[series])];
  if (selectedBenchmarks.includes(reportRequestName)) {
    lines.push(result.benchmark);
  }
  lines = [
    ...lines,
    ...result.benchmarks.filter((benchmark) =>
      selectedBenchmarks.includes(benchmark.type)
    ),
  ];
  for (const line of lines) {
    const lineKey = line.type;
    lineKeys.push(lineKey);
    lineLabels.push(line.label.replaceAll("MOMENTUM", reportRequestName));

    for (const [metricKey, metricValue] of Object.entries(line.metrics)) {
      if (!metricsData[metricKey]) {
        metricsData[metricKey] = {};
      }
      metricsData[metricKey][lineKey] = metricValue;
    }
  }
  for (const metricKey of metricsInOrder) {
    const metricLabel = metricLabelsByMetricKey[metricKey];
    const metricEntry: Array<string> = [metricLabel];
    for (const lineKey of lineKeys) {
      const format = formatMetricFuncsByMetricKey[metricKey];
      metricEntry.push(format(metricsData[metricKey][lineKey]));
    }
    data.push(metricEntry);
  }

  return (
    <PerformanceReportsResultTable
      columns={["Metric", ...lineLabels]}
      data={data}
      csvFileName={`${reportRequestTag} - Risk Metrics.csv`}
    />
  );
};
