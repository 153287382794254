import { createContext, useContext } from "react";
import { StrategyEvals } from "../types";

export interface StrategyMonitoringLiveEvalsContextState {
  evals: StrategyEvals;
  evalsUpdatedAt: Date;
}

export const StrategyMonitoringLiveEvalsContext =
  createContext<StrategyMonitoringLiveEvalsContextState>({
    evals: [],
    evalsUpdatedAt: new Date(),
  });

export const useStrategyMonitoringLiveEvalsContext = () =>
  useContext(StrategyMonitoringLiveEvalsContext);
