import { createContext, useContext } from "react";
import { noop } from "../helpers";
import { Strategy } from "../types";

export const dashboardSections = {
  monitoring: "monitoring",
  performance: "performance",
} as const;
export type DashboardSection = keyof typeof dashboardSections;

export interface DashboardContextState {
  hubUrl: string;
  strategies: Strategy[];
  isLoadingStrategies: boolean;
  refetchStrategies: () => void;
  section: DashboardSection;
  navigateTo: (section: DashboardSection) => void;
}

export const DashboardContext = createContext<DashboardContextState>({
  hubUrl: "",
  strategies: [],
  isLoadingStrategies: false,
  refetchStrategies: noop,
  section: "monitoring",
  navigateTo: noop,
});

export const useDashboardContext = () => useContext(DashboardContext);
