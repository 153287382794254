const fixedColors = {
  dir: "#ff3d00",
  field: "#FFDAB9",
  yellow: "#FFFF00",
  submin: "#8E4585",
  submax: "#007FFF",
  mdlmin: "#FF7F50",
  mdlmax: "#808000",
};

export default {
  almond: "#EFDECD",
  amber: "#FFBF00",
  aluminum: "#848484",
  beech: "#7B4F2A",
  birch: "#FFE4C4",
  black: "#696969",
  blue: "#0000FF",
  box: "#987654",
  bronze: "#CD7F32",
  brown: "#964B00",
  cedar: "#A45A52",
  cherry: "#DE3163",
  clear: "#D8D8D8",
  copper: "#B87333",
  cyan: "#00FFFF",
  gold: "#FFD700",
  green: "#008000",
  grey: "#808080",
  iron: "#444C52",
  lead: "#212121",
  magenta: "#FF00FF",
  mahogany: "#5C4033",
  maple: "#DB6514",
  nickel: "#727472",
  oak: "#806517",
  opal: "#A8C3BC",
  orange: "#FFA500",
  pink: "#FFC0CB",
  purple: "#800080",
  red: "#FF0000",
  rust: "#B7410E",
  silver: "#C0C0C0",
  tan: "#BC8F8F",
  teal: "#008080",
  tin: "#7F7F7F",
  walnut: "#79443B",
  white: "#FFFFFF",
  ...fixedColors,
};
