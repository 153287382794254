import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { StrategySignalSwitchRuleEntry } from "../../../types";
import { StrategyMonitoringSignalTableSwitchRuleConditionsTable } from "./SwitchRuleConditionsTable";

export interface StrategyMonitoringSignalTableSwitchRuleRowProps {
  entry: StrategySignalSwitchRuleEntry;
}
export const StrategyMonitoringSignalTableSwitchRuleRow = ({
  entry,
}: StrategyMonitoringSignalTableSwitchRuleRowProps) => {
  const [expandConditionResults, setExpandConditionResults] = useState(false);

  const conditionsCount = entry.condition_entries.length;
  const satisfiedConditionsCount = entry.condition_entries.filter(
    (condition) => condition.value
  ).length;

  return (
    <>
      <TableRow style={{ border: "unset" }}>
        <TableCell component="th" scope="row">
          {`Rule ${entry.label}`}
        </TableCell>
        <TableCell>{entry.value ? "YES" : "NO"}</TableCell>
        <TableCell>{`${satisfiedConditionsCount}/${conditionsCount}`}</TableCell>
        <TableCell size="small">
          <IconButton
            size="small"
            onClick={() => setExpandConditionResults(!expandConditionResults)}
          >
            {expandConditionResults ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <StrategyMonitoringSignalTableSwitchRuleConditionsTable
        expandConditionResults={expandConditionResults}
        ruleLabel={entry.label}
        conditionEntries={entry.condition_entries}
      />
    </>
  );
};
