import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../Dashboard/useDashboardContext";
import { StrategyMonitoringFiltersContext } from "./useStrategyMonitoringFiltersContext";
import {
  StrategyMonitoringFilters,
  reloadWithFiltersIfNecessary,
  useStrategyMonitoringFiltersFromQueryParams,
} from "./useStrategyMonitoringFiltersFromQueryParams";

export interface StrategyMonitoringFiltersContextProviderProps {
  children: React.ReactNode;
}
export const StrategyMonitoringFiltersContextProvider = ({
  children,
}: StrategyMonitoringFiltersContextProviderProps) => {
  const { strategies } = useDashboardContext();

  const filtersFromQueryParams =
    useStrategyMonitoringFiltersFromQueryParams(strategies);
  const [filters, setFilters] = useState<StrategyMonitoringFilters>(
    filtersFromQueryParams
  );
  useEffect(() => {
    reloadWithFiltersIfNecessary(filters);
  }, [filters]);

  const [periodIndex, setPeriodIndex] = useState<number | null>(null);

  return (
    <StrategyMonitoringFiltersContext.Provider
      value={{
        filters,
        setFilters,
        periodIndex,
        setPeriodIndex,
      }}
    >
      {children}
    </StrategyMonitoringFiltersContext.Provider>
  );
};
