import { TableCell, TableRow } from "@mui/material";
import { StrategyOverrideEvalResult } from "../../types";

export interface StrategyMonitoringOverrideEvalResultRowProps {
  overrideResult: StrategyOverrideEvalResult;
}
export const StrategyMonitoringOverrideEvalResultRow = ({
  overrideResult,
}: StrategyMonitoringOverrideEvalResultRowProps) => {
  return (
    <TableRow key={overrideResult.override_tag}>
      <TableCell component="th" scope="row">
        {overrideResult.override_tag}
        {overrideResult.description ? ` (${overrideResult.description})` : null}
      </TableCell>
      <TableCell>{overrideResult.value ? "YES" : "NO"}</TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
