import {
  StrategyMonitoringSignalSeriesConfig,
  StrategyMonitoringSignalSeriesOrder,
  resolveTextAndXAndY,
} from "../SignalSeriesConfig";
import colors from "../colors";

export const config: StrategyMonitoringSignalSeriesConfig = {
  // Prices
  ["PRICE"]: {
    label: "PRICE",
    color: colors.field,
    resolveTextAndXAndY,
  },
  ["PRICE_N"]: {
    label: "PRICE_N",
    color: colors.clear,
    resolveTextAndXAndY,
  },
  ["TAN"]: {
    label: "TAN",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  ["PINK"]: {
    label: "PINK",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  ["WHITE"]: {
    label: "WHITE",
    color: colors.white,
    resolveTextAndXAndY,
  },
  ["GREEN"]: {
    label: "GREEN",
    color: colors.green,
    resolveTextAndXAndY,
  },
  ["BLUE"]: {
    label: "BLUE",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  ["BROWN"]: {
    label: "BROWN",
    color: colors.brown,
    resolveTextAndXAndY,
  },
  ["GOLD"]: {
    label: "GOLD",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  ["TAN10"]: {
    label: "TAN10",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  ["PINK10"]: {
    label: "PINK10",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  ["WHITE10"]: {
    label: "WHITE10",
    color: colors.white,
    resolveTextAndXAndY,
  },
  ["GREEN10"]: {
    label: "GREEN10",
    color: colors.green,
    resolveTextAndXAndY,
  },
  ["BLUE10"]: {
    label: "BLUE10",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  ["BROWN10"]: {
    label: "BROWN10",
    color: colors.brown,
    resolveTextAndXAndY,
  },
  ["GOLD10"]: {
    label: "GOLD10",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  // Slopes
  ["TANPV"]: {
    label: "TANPV",
    color: colors.tan,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["PINKPV"]: {
    label: "PINKPV",
    color: colors.pink,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["BLUEPV"]: {
    label: "BLUEPV",
    color: colors.blue,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["SILVER10"]: {
    label: "SILVER10",
    color: colors.silver,
    resolveTextAndXAndY,
  },
  ["RED10"]: {
    label: "RED10",
    color: colors.red,
    resolveTextAndXAndY,
  },
  ["GREENPV10"]: {
    label: "GREENPV10",
    color: colors.green,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["BLUEPV10"]: {
    label: "BLUEPV10",
    color: colors.blue,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  // Others
  ["TRADE DIR"]: {
    label: "TRADEDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
  ["POSITION DIR"]: {
    label: "POSDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
};

export const order: StrategyMonitoringSignalSeriesOrder = [
  "PRICE",
  "PRICE_N",
  "TAN",
  "PINK",
  "WHITE",
  "GREEN",
  "BLUE",
  "BROWN",
  "GOLD",
  "TAN10",
  "PINK10",
  "WHITE10",
  "GREEN10",
  "BLUE10",
  "BROWN10",
  "GOLD10",
  "TANPV",
  "PINKPV",
  "BLUEPV",
  "SILVER10",
  "RED10",
  "GREENPV10",
  "BLUEPV10",
  "TRADEDIR",
  "POSDIR",
];
