import { PerformanceReportsContainer } from "./PerformanceReportsContainter";
import { PerformanceReportsContextProvider } from "./PerformanceReportsContextProvider";

export const PerformanceReports = () => {
  return (
    <PerformanceReportsContextProvider>
      <PerformanceReportsContainer />
    </PerformanceReportsContextProvider>
  );
};
