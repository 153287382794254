import { Box, useTheme } from "@mui/material";
import { Datum, Layout, PlotData } from "plotly.js";
import Plot from "react-plotly.js";
import { toDateStr } from "../../helpers";
import {
  PerformanceReportResult,
  PerformanceReportsResultSeries,
} from "../../types";
import useWindowDimensions from "../../useWindowDimensions";

const linePropsByLineType: {
  [key: string]: { color: string; width?: number };
} = {
  momentum: { color: "#00FFFF", width: 3 }, // Cyan, default width
  net_of_fees: { color: "#800080", width: 6 }, // Purple, thicker line
  benchmark: { color: "#FFC0CB" }, // Pink for CST Benchmark, distinct from SPY
  IWM: { color: "#32CD32" }, // Lime Green, good for both dark and light backgrounds
  SPY: { color: "#6495ED" }, // Cornflower Blue, visible on light backgrounds
  QQQ: { color: "#8A2BE2" }, // Orange, default width
} as const;

export interface PerformanceReportsResultPlotsProps {
  reportRequestName: string;
  selectedSeries: PerformanceReportsResultSeries[];
  selectedBenchmarks: string[];
  result: PerformanceReportResult;
}
export const PerformanceReportsResultPlots = ({
  reportRequestName,
  selectedSeries,
  selectedBenchmarks,
  result,
}: PerformanceReportsResultPlotsProps) => {
  const theme = useTheme();
  const { width, height } = useWindowDimensions();

  const nomValueData: Array<Partial<PlotData>> = [];
  const cummReturnValueData: Array<Partial<PlotData>> = [];

  const plotLayoutTitleParts: string[] = [];

  let lines = [...selectedSeries.map((series) => result[series])];
  if (selectedBenchmarks.includes(reportRequestName)) {
    lines.push(result.benchmark);
  }
  lines = [
    ...lines,
    ...result.benchmarks.filter((benchmark) =>
      selectedBenchmarks.includes(benchmark.type)
    ),
  ];
  for (const line of lines) {
    const lineLabel = line.label.replaceAll("MOMENTUM", reportRequestName);

    plotLayoutTitleParts.push(lineLabel);

    const nomValueX: Datum[] = [];
    const nomValueY: Datum[] = [];
    const cummReturnValueX: Datum[] = [];
    const cummReturnValueY: Datum[] = [];

    for (let i = 0; i < line.grouped_market_value_entries.length; i++) {
      const entry = line.grouped_market_value_entries[i];

      const xValue = toDateStr(entry.date);

      nomValueX.push(xValue);
      nomValueY.push(entry.value_at_close);
      cummReturnValueX.push(xValue);
      cummReturnValueY.push(entry.cumm_return);
    }

    const commonLinePlotData: Partial<PlotData> = {
      type: "scatter",
      mode: "lines",
      line: linePropsByLineType[line.type],
      name: lineLabel,
      showlegend: true,
    };
    nomValueData.push({
      ...commonLinePlotData,
      x: nomValueX,
      y: nomValueY,
    });
    cummReturnValueData.push({
      ...commonLinePlotData,
      x: cummReturnValueX,
      y: cummReturnValueY,
    });
  }

  const plotLayoutTitle = plotLayoutTitleParts.join(" vs. ");
  const commonPlotLayout: Partial<Layout> = {
    width: width,
    height: height * 0.7,
    paper_bgcolor: theme.palette.background.paper,
    plot_bgcolor: theme.palette.background.paper,
    font: { color: theme.palette.text.primary },
    legend: { orientation: "h", itemclick: "toggle", itemdoubleclick: false },
    title: plotLayoutTitle,
  };
  return (
    <>
      <Box>
        <Plot layout={commonPlotLayout} data={nomValueData} />
      </Box>
      <Box>
        <Plot layout={commonPlotLayout} data={cummReturnValueData} />
      </Box>
    </>
  );
};
