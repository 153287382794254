import {
  StrategyMonitoringLiveSignalsContextProvider,
  StrategyMonitoringSignalTable,
  StrategyMonitoringSignalsContextProvider,
  StrategyMonitoringSignalsPlots,
} from "./Signals";
import { strategyMonitoringSignalSeriesConfig } from "./Signals/SignalSeriesConfig";
import { StrategyMonitoringEvalData } from "./StrategyMonitoringEvalData";
import { StrategyMonitoringEvalDataAndResultsHeader } from "./StrategyMonitoringEvalDataAndResultsHeader";
import { StrategyMonitoringEvalResults } from "./StrategyMonitoringEvalResults";
import { StrategyMonitoringEvalsContextProvider } from "./StrategyMonitoringEvalsContextProvider";
import { StrategyMonitoringLiveEvalsContextProvider } from "./StrategyMonitoringLiveEvalsContextProvider";
import { useStrategyMonitoringFiltersContext } from "./useStrategyMonitoringFiltersContext";

export const StrategyMonitoringContent = () => {
  const {
    filters: { strategy },
  } = useStrategyMonitoringFiltersContext();

  if (strategyMonitoringSignalSeriesConfig[strategy.name]) {
    return (
      <StrategyMonitoringSignalsContextProvider>
        <StrategyMonitoringLiveSignalsContextProvider>
          <StrategyMonitoringSignalsPlots />
          <StrategyMonitoringSignalTable />
        </StrategyMonitoringLiveSignalsContextProvider>
      </StrategyMonitoringSignalsContextProvider>
    );
  }
  return (
    <StrategyMonitoringEvalsContextProvider>
      <StrategyMonitoringLiveEvalsContextProvider>
        <StrategyMonitoringEvalDataAndResultsHeader />
        <StrategyMonitoringEvalData />
        <StrategyMonitoringEvalResults />
      </StrategyMonitoringLiveEvalsContextProvider>
    </StrategyMonitoringEvalsContextProvider>
  );
};
