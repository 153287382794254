import {
  StrategyMonitoringSignalSeriesConfig,
  StrategyMonitoringSignalSeriesOrder,
  resolveTextAndXAndY, resolveTextAndXAndYForMaxMin,
} from "../SignalSeriesConfig";
import colors from "../colors";

export const config: StrategyMonitoringSignalSeriesConfig = {
  // Prices
  ["PRICE"]: {
    label: "PRICE",
    color: colors.field,
    resolveTextAndXAndY,
  },
  ["PRICE_N"]: {
    label: "PRICE_N",
    color: colors.clear,
    resolveTextAndXAndY,
  },
  ["TAN"]: {
    label: "TAN",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  ["PINK"]: {
    label: "PINK",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  ["WHITE"]: {
    label: "WHITE",
    color: colors.white,
    resolveTextAndXAndY,
  },
  ["GREEN"]: {
    label: "GREEN",
    color: colors.green,
    resolveTextAndXAndY,
  },
  ["BLUE"]: {
    label: "BLUE",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  ["YELLOW"]: {
    label: "YELLOW",
    color: colors.yellow,
    resolveTextAndXAndY,
  },
  ["RUST"]: {
    label: "RUST",
    color: colors.rust,
    resolveTextAndXAndY,
  },
  ["CYAN"]: {
    label: "CYAN",
    color: colors.cyan,
    resolveTextAndXAndY,
  },
  ["GOLD"]: {
    label: "GOLD",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  ["BROWN"]: {
    label: "BROWN",
    color: colors.brown,
    resolveTextAndXAndY,
  },
  ["AMBER"]: {
    label: "AMBER",
    color: colors.amber,
    resolveTextAndXAndY,
  },
  ["TAN10"]: {
    label: "TAN10",
    color: colors.tan,
    resolveTextAndXAndY,
  },
  ["PINK10"]: {
    label: "PINK10",
    color: colors.pink,
    resolveTextAndXAndY,
  },
  ["WHITE10"]: {
    label: "WHITE10",
    color: colors.white,
    resolveTextAndXAndY,
  },
  ["GREEN10"]: {
    label: "GREEN10",
    color: colors.green,
    resolveTextAndXAndY,
  },
  ["BLUE10"]: {
    label: "BLUE10",
    color: colors.blue,
    resolveTextAndXAndY,
  },
  ["YELLOW10"]: {
    label: "YELLOW10",
    color: colors.yellow,
    resolveTextAndXAndY,
  },
  ["RUST10"]: {
    label: "RUST10",
    color: colors.rust,
    resolveTextAndXAndY,
  },
  ["BROWN10"]: {
    label: "BROWN10",
    color: colors.brown,
    resolveTextAndXAndY,
  },
  ["GOLD10"]: {
    label: "GOLD10",
    color: colors.gold,
    resolveTextAndXAndY,
  },
  // Slopes
  ["SILVER"]: {
    label: "SILVER",
    color: colors.silver,
    resolveTextAndXAndY,
  },
  ["BLACK"]: {
    label: "BLACK",
    color: colors.black,
    resolveTextAndXAndY,
  },
  ["RED"]: {
    label: "RED",
    color: colors.red,
    resolveTextAndXAndY,
  },
  ["OPAL"]: {
    label: "OPAL",
    color: colors.opal,
    resolveTextAndXAndY,
  },
  ["PINKPV"]: {
    label: "PINKPV",
    color: colors.pink,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["WHITEPV"]: {
    label: "WHITEPV",
    color: colors.white,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["BLUEPV"]: {
    label: "BLUEPV",
    color: colors.blue,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["SILVER10"]: {
    label: "SILVER10",
    color: colors.silver,
    resolveTextAndXAndY,
  },
  ["BLACK10"]: {
    label: "BLACK10",
    color: colors.black,
    resolveTextAndXAndY,
  },
  ["RED10"]: {
    label: "RED10",
    color: colors.red,
    resolveTextAndXAndY,
  },
  ["WHITEPV10"]: {
    label: "WHITEPV10",
    color: colors.white,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["GREENPV10"]: {
    label: "GREENPV10",
    color: colors.green,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  ["BLUEPV10"]: {
    label: "BLUEPV10",
    color: colors.blue,
    resolveTextAndXAndY,
    overrides: {
      type: "scatter",
      mode: "markers",
      showlegend: true,
    },
  },
  // SUBMAX / SUBMIN
  ["SUBMAX"]: {
    label: "SUBMAX2",
    color: colors.submax,
    size: 10,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  ["SUBMIN"]: {
    label: "SUBMIN2",
    color: colors.submin,
    size: 10,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  ["MDLMAX"]: {
    label: "MDLMAX2",
    color: colors.mdlmax,
    size: 16,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  ["MDLMIN"]: {
    label: "MDLMIN2",
    color: colors.mdlmin,
    size: 16,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  ["MDLMAX10"]: {
    label: "MDLMAX10",
    color: colors.mdlmax,
    size: 16,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  ["MDLMIN10"]: {
    label: "MDLMIN10",
    color: colors.mdlmin,
    size: 16,
    resolveTextAndXAndY: resolveTextAndXAndYForMaxMin,
  },
  // Others
  ["TRADE DIR"]: {
    label: "TRADEDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
  ["POSITION DIR"]: {
    label: "POSDIR",
    color: colors.dir,
    resolveTextAndXAndY,
  },
};

export const order: StrategyMonitoringSignalSeriesOrder = [
  "PRICE",
  "PRICE_N",
  "TAN",
  "PINK",
  "WHITE",
  "GREEN",
  "BLUE",
  "YELLOW",
  "RUST",
  "CYAN",
  "GOLD",
  "BROWN",
  "AMBER",
  "TAN10",
  "PINK10",
  "WHITE10",
  "GREEN10",
  "BLUE10",
  "YELLOW10",
  "RUST10",
  "BROWN10",
  "GOLD10",
  "SILVER",
  "BLACK",
  "RED",
  "OPAL",
  "PINKPV",
  "WHITEPV",
  "BLUEPV",
  "SILVER10",
  "BLACK10",
  "RED10",
  "WHITEPV10",
  "GREENPV10",
  "BLUEPV10",
  "SUBMAX2",
  "SUBMIN2",
  "MDLMAX2",
  "MDLMIN2",
  "MDLMAX10",
  "MDLMIN10",
  "TRADEDIR",
  "POSDIR",
];
