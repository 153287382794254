import {
  StrategyMonitoringSignalSeriesConfig,
  StrategyMonitoringSignalSeriesOrder,
} from "./SignalSeriesConfig";
import {
  peaks_and_valleys_config,
  peaks_and_valleys_order,
  pv2_config,
  pv2_order,
  tsla_shark_45_2_config,
  tsla_shark_45_2_order,
  hedgehog_45_config,
  hedgehog_45_order,
} from "./strategies";

export const strategyMonitoringSignalSeriesConfig: {
  [key: string]: StrategyMonitoringSignalSeriesConfig;
} = {
  tsla_shark_45_2: tsla_shark_45_2_config,
  peaks_and_valleys: peaks_and_valleys_config,
  pv2: pv2_config,
  hedgehog_45: hedgehog_45_config,
};

export const strategyMonitoringSignalSeriesOrder: {
  [key: string]: StrategyMonitoringSignalSeriesOrder;
} = {
  tsla_shark_45_2: tsla_shark_45_2_order,
  peaks_and_valleys: peaks_and_valleys_order,
  pv2: pv2_order,
  hedgehog_45: hedgehog_45_order,
};
