import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export interface ActionControlsConfirmationDialogProps {
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  isConfirming: boolean;
}

export const ActionControlsConfirmationDialog = ({
  title,
  message,
  onClose,
  onConfirm,
  isConfirming,
}: ActionControlsConfirmationDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isConfirming} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isConfirming} onClick={onConfirm} autoFocus>
          Proceed
          {isConfirming && <CircularProgress />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
