import {
  Button,
  Grid,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { GeneratePerformanceReportRequestParams } from "../../types";
import { buildTradesSourceKey, isValidNumericValue } from "./utils";

export interface EditTradesSourceFormProps {
  params: GeneratePerformanceReportRequestParams;
  tradesSourceIndex: number;
  updateTradesSourceAllocation: (
    tradesSourceIndex: number,
    allocation: number
  ) => void;
  removeTradesSource: (tradesSourceIndex: number) => void;
}
export const EditTradesSourceForm = ({
  params,
  tradesSourceIndex,
  updateTradesSourceAllocation,
  removeTradesSource,
}: EditTradesSourceFormProps) => {
  const tradesSource = params.trades_sources[tradesSourceIndex];
  const tradesSourceKey = buildTradesSourceKey(tradesSource);

  const allocation = String(tradesSource.allocation);
  const onChangeAllocation: TextFieldProps["onChange"] = (event) => {
    const valueStr: string = event.target.value;
    if (isValidNumericValue(valueStr)) {
      updateTradesSourceAllocation(tradesSourceIndex, Number(valueStr));
    } else {
      updateTradesSourceAllocation(tradesSourceIndex, Number(allocation));
    }
  };

  return (
    <Grid container columnSpacing={2} marginBottom={1}>
      <Grid item xs={6}>
        <TextField
          id="strategy"
          label="Strategy"
          variant="outlined"
          size="small"
          select
          value={tradesSourceKey}
          disabled
          fullWidth
        >
          <MenuItem key={tradesSourceKey} value={tradesSourceKey}>
            {[tradesSource.source_label, tradesSource.instrument_label].join(
              " - "
            )}
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="allocation"
          label="Allocation"
          variant="outlined"
          size="small"
          value={allocation}
          onChange={onChangeAllocation}
          fullWidth
        />
      </Grid>
      <Grid item xs={2} justifyContent="center" alignItems="center">
        <Button
          color="error"
          variant="outlined"
          onClick={() => removeTradesSource(tradesSourceIndex)}
          fullWidth
          sx={{ height: "100%" }}
        >
          DEL
        </Button>
      </Grid>
    </Grid>
  );
};
