import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { fetchStrategySignals } from "../../api";
import { StrategySignal } from "../../types";
import { useStrategyMonitoringFiltersContext } from "../useStrategyMonitoringFiltersContext";
import {
  StrategyMonitoringSignalsContext,
  StrategyMonitoringSignalsContextState,
} from "./useSignalsContext";

const seriesVisibilityKey =
  "StrategyMonitoringSignalsContextProvider.visibilityItem" as const;

export interface StrategyMonitoringSignalsContextProviderProps {
  children: React.ReactNode;
}
export const StrategyMonitoringSignalsContextProvider = ({
  children,
}: StrategyMonitoringSignalsContextProviderProps) => {
  const {
    filters: { strategy, startDate, endDate },
  } = useStrategyMonitoringFiltersContext();

  const { data: signals, isLoading: isLoadingSignals } = useQuery<
    StrategySignal[]
  >({
    queryKey: [
      "strategies",
      strategy?.app_id,
      "signals",
      startDate.getTime(),
      endDate?.getTime(),
    ],
    queryFn: () =>
      fetchStrategySignals({
        strategy,
        startDate,
        endDate: endDate || new Date(),
      }),
    initialData: [],
  });

  const selectionKey = `${strategy.account.name}:${strategy.instrument.name}:${strategy.name}`;

  const [seriesVisibility, setSeriesVisibility] = useState<
    StrategyMonitoringSignalsContextState["seriesVisibility"]
  >(() => {
    const item = localStorage.getItem(seriesVisibilityKey);
    if (!item) {
      return {};
    }
    return JSON.parse(item);
  });
  const toggleSeriesVisibility = useCallback(
    (series: string) =>
      setSeriesVisibility((currentState) => {
        const strategyItem = currentState[selectionKey] || {};
        return {
          ...currentState,
          [selectionKey]: {
            ...strategyItem,
            [series]:
              strategyItem[series] === undefined ||
              strategyItem[series] === null
                ? false
                : !strategyItem[series],
          },
        };
      }),
    [selectionKey, setSeriesVisibility]
  );
  const resolveSeriesVisibility = useCallback(
    (seriesKey: string): boolean | "legendonly" => {
      const strategyItem = seriesVisibility[selectionKey] || {};
      const value =
        strategyItem[seriesKey] === undefined ||
        strategyItem[seriesKey] === null ||
        strategyItem[seriesKey];
      return value ? true : "legendonly";
    },
    [selectionKey, seriesVisibility]
  );

  useEffect(() => {
    if (seriesVisibility) {
      localStorage.setItem(
        seriesVisibilityKey,
        JSON.stringify(seriesVisibility)
      );
    }
  }, [seriesVisibility]);

  return (
    <StrategyMonitoringSignalsContext.Provider
      value={{
        signals,
        isLoadingSignals,
        seriesVisibility,
        resolveSeriesVisibility,
        toggleSeriesVisibility,
      }}
    >
      {children}
    </StrategyMonitoringSignalsContext.Provider>
  );
};
