import { Box, Button, ButtonGroup, Snackbar, Typography } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { useCallback, useMemo, useState } from "react";
import { updateStrategy } from "../../../api";
import { strategyModes, strategyUpdateTargets } from "../../../types";
import { useStrategyMonitoringFiltersContext } from "../../useStrategyMonitoringFiltersContext";
import { useStrategyMonitoringStatsAndControlsContext } from "../useStatsAndControlsContext";
import {
  StrategyActionConfirmationDetails,
  StrategyActionConfirmationDetailsResolver,
  StrategyActionIntent,
  strategyActionConfirmationDetailsResolverByIntent,
  strategyActionIntents,
} from "./ActionConfirmationDetails";
import { ActionControlsConfirmationDialog } from "./ActionControlsConfirmationDialog";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ActionControls = () => {
  const {
    filters: { strategy },
  } = useStrategyMonitoringFiltersContext();
  const {
    stats: { allocation, mode, position },
    positionChangeInProgress,
    setPositionChangeInProgress,
  } = useStrategyMonitoringStatsAndControlsContext();

  const positionUnits = position.units;
  const positionState = position.state;

  const [targetIntent, setTargetIntent] = useState<StrategyActionIntent | "">(
    ""
  );

  const confirmationDetails = useMemo<
    StrategyActionConfirmationDetails | ""
  >(() => {
    if (!targetIntent) {
      return "";
    }
    const resolver: StrategyActionConfirmationDetailsResolver =
      strategyActionConfirmationDetailsResolverByIntent[targetIntent];
    return resolver({ strategy, allocation, positionState, positionUnits });
  }, [strategy, positionState, position, targetIntent]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [alert, setAlert] = useState<
    Partial<{
      severity: "success" | "error";
      message: string;
    }>
  >({});
  const handleCloseAlert = useCallback(() => setAlert({}), [setAlert]);

  const handleCloseConfirm = useCallback(() => {
    setTargetIntent("");
    setIsUpdating(false);
  }, [setTargetIntent, setIsUpdating]);
  const handleConfirm = useCallback(() => {
    if (!confirmationDetails) {
      handleCloseConfirm();
      return;
    }

    setIsUpdating(true);

    updateStrategy(confirmationDetails.requestParams)
      .then((result) => {
        if (
          confirmationDetails.requestParams.target ===
          strategyUpdateTargets.position
        ) {
          setPositionChangeInProgress(true);
        }
        setAlert({
          severity: "success",
          message: result.message,
        });
      })
      .catch((error) => {
        console.error(error);
        setAlert({
          severity: "error",
          message: error.message,
        });
      })
      .finally(() => handleCloseConfirm());
  }, [strategy, confirmationDetails, setAlert, handleCloseConfirm]);

  const canShort =
    !positionChangeInProgress &&
    mode !== strategyModes.OFF &&
    positionUnits >= 0;
  const canClose =
    !positionChangeInProgress &&
    mode !== strategyModes.OFF &&
    positionUnits !== 0;
  const canLong =
    !positionChangeInProgress &&
    mode !== strategyModes.OFF &&
    positionUnits <= 0;

  const canPause = !positionChangeInProgress && mode === strategyModes.TRADE;
  const canResume = !positionChangeInProgress && mode === strategyModes.EVAL;
  const canStop = !positionChangeInProgress && (canPause || canResume);
  const canStart = !positionChangeInProgress && mode === strategyModes.OFF;

  return (
    <Box display="flex" flexDirection="column" rowGap={0.5}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={0.5}
      >
        <Typography variant="subtitle1">MANUAL TRADING</Typography>
        <ButtonGroup
          fullWidth
          variant="outlined"
          color="secondary"
          orientation="horizontal"
        >
          <Button
            disabled={!canShort}
            onClick={() => setTargetIntent(strategyActionIntents.SHORT)}
          >
            SHORT
          </Button>
          <Button
            disabled={!canClose}
            onClick={() => setTargetIntent(strategyActionIntents.CLOSE)}
          >
            CLOSE
          </Button>
          <Button
            disabled={!canLong}
            onClick={() => setTargetIntent(strategyActionIntents.LONG)}
          >
            LONG
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={0.5}
      >
        <Typography variant="subtitle1">AUTO TRADING</Typography>
        <Button
          fullWidth
          color="warning"
          variant="outlined"
          disabled={!canPause && !canResume}
          onClick={() => {
            if (canPause) {
              setTargetIntent(strategyActionIntents.PAUSE);
            } else if (canResume) {
              setTargetIntent(strategyActionIntents.RESUME);
            }
          }}
        >
          {canPause && "PAUSE"}
          {canResume && "RESUME"}
          {!canPause && !canResume && "PAUSE/RESUME"}
        </Button>
        <Button
          fullWidth
          color="error"
          variant="outlined"
          disabled={!canStop && !canStart}
          onClick={() => {
            if (canStop) {
              setTargetIntent(strategyActionIntents.STOP);
            } else if (canStart) {
              setTargetIntent(strategyActionIntents.START);
            }
          }}
        >
          {canStop && "STOP"}
          {canStart && "START"}
          {!canStop && !canStart && "STOP/START"}
        </Button>
      </Box>
      {confirmationDetails && (
        <ActionControlsConfirmationDialog
          title={confirmationDetails.title}
          message={confirmationDetails.message}
          onClose={handleCloseConfirm}
          onConfirm={handleConfirm}
          isConfirming={isUpdating}
        />
      )}
      {alert.severity && alert.message && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};
