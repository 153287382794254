import {
  Button,
  Grid,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import {
  GeneratePerformanceReportRequestParams,
  PerformanceReportTradesSource,
  Strategy,
} from "../../types";
import {
  buildTradesSourceKey,
  isSubmittableNumericValue,
  isValidNumericValue,
  mapStrategyToTradesSource,
} from "./utils";

export interface AddTradesSourceFormProps {
  strategies: Array<Strategy>;
  params: GeneratePerformanceReportRequestParams;
  addTradesSource: (tradesSource: PerformanceReportTradesSource) => void;
}
export const AddTradesSourceForm = ({
  strategies,
  params,
  addTradesSource,
}: AddTradesSourceFormProps) => {
  const takenInstrumentIds = params.trades_sources.map(
    (tradesSource) => tradesSource.instrument_id
  );

  const tradesSources = strategies.map(mapStrategyToTradesSource);
  const tradesSourceOptions = tradesSources.filter(
    (tradesSource) => !takenInstrumentIds.includes(tradesSource.instrument_id)
  );
  const tradesSourcesByKey = tradesSourceOptions.reduce<{
    [key: string]: PerformanceReportTradesSource;
  }>((dict, tradesSource) => {
    const key = buildTradesSourceKey(tradesSource);
    dict[key] = tradesSource;
    return dict;
  }, {});

  const [tradesSource, setTradesSource] =
    useState<PerformanceReportTradesSource | null>(null);
  const [allocation, setAllocation] = useState<string>("");

  const onChangeTradesSource: TextFieldProps["onChange"] = (event) => {
    const tradesSourceKey = event.target.value;
    setTradesSource(tradesSourcesByKey[tradesSourceKey]);
  };
  const onChangeAllocation: TextFieldProps["onChange"] = (event) => {
    const valueStr: string = event.target.value;
    if (isValidNumericValue(valueStr)) {
      setAllocation(valueStr);
    } else {
      setAllocation(allocation);
    }
  };

  const areParamsValid =
    !!tradesSource && isSubmittableNumericValue(allocation);
  const onSubmit = () => {
    if (areParamsValid) {
      addTradesSource({
        ...tradesSource,
        allocation: Number(allocation),
      });
      setTradesSource(null);
      setAllocation("");
    }
  };

  const tradesSourceKey = tradesSource
    ? buildTradesSourceKey(tradesSource)
    : "";

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <TextField
          id="tradesSource"
          label="Strategy"
          variant="outlined"
          size="small"
          placeholder="Strategy"
          select
          value={tradesSourceKey}
          onChange={onChangeTradesSource}
          fullWidth
        >
          {tradesSourceOptions.map(
            (tradesSource: PerformanceReportTradesSource) => {
              const tradesSourceKey = buildTradesSourceKey(tradesSource);
              return (
                <MenuItem key={tradesSourceKey} value={tradesSourceKey}>
                  {[
                    tradesSource.source_label,
                    tradesSource.instrument_label,
                  ].join(" - ")}
                </MenuItem>
              );
            }
          )}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="allocation"
          label="Allocation"
          variant="outlined"
          size="small"
          value={allocation}
          onChange={onChangeAllocation}
          fullWidth
        />
      </Grid>
      <Grid item xs={2} justifyContent="center" alignItems="center">
        <Button
          color="primary"
          variant="outlined"
          disabled={!areParamsValid}
          onClick={onSubmit}
          fullWidth
          sx={{ height: "100%" }}
        >
          ADD
        </Button>
      </Grid>
    </Grid>
  );
};
