import { Alert, AlertTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import {
  NegotiateSignalRAccessTokenResponse,
  fetchStrategies,
  negotiateSignalRAccessToken,
} from "../api";
import { Strategy } from "../types";
import {
  DashboardContext,
  DashboardSection,
  dashboardSections,
} from "./useDashboardContext";

export interface DashboardContextProviderProps {
  children: React.ReactNode;
}
export const DashboardContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const hubUrlMutation = useMutation<NegotiateSignalRAccessTokenResponse>({
    mutationFn: negotiateSignalRAccessToken,
  });
  const {
    data: { url: hubUrl } = { url: "" },
    isIdle: isIdleHubUrl,
    isLoading: isLoadingHubUrl,
    mutate: refetchHubUrl,
  } = hubUrlMutation;

  const strategiesMutation = useMutation<Strategy[]>({
    mutationFn: fetchStrategies,
  });
  const {
    data: strategies,
    isIdle: isIdleStrategies,
    isLoading: isLoadingStrategies,
    mutate: refetchStrategies,
  } = strategiesMutation;

  useEffect(() => {
    refetchHubUrl();
    refetchStrategies();
  }, []);

  const [section, navigateTo] = useState<DashboardSection>(
    dashboardSections.monitoring
  );

  if (
    isIdleHubUrl ||
    isLoadingHubUrl ||
    isIdleStrategies ||
    isLoadingStrategies
  ) {
    return <Loader />;
  }
  if (!hubUrl) {
    return (
      <Alert severity="warning">
        <AlertTitle>No SignalR Hub?</AlertTitle>
        Seems like we could not load the SignalR config. Please contact your
        support.
      </Alert>
    );
  }
  if (!strategies) {
    return (
      <Alert severity="warning">
        <AlertTitle>No Strategies?</AlertTitle>
        Seems like we could not load any strategies. Please contact support.
      </Alert>
    );
  }

  return (
    <DashboardContext.Provider
      value={{
        hubUrl,
        strategies,
        isLoadingStrategies: isLoadingStrategies,
        refetchStrategies,
        section,
        navigateTo,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
