import { TableCell, TableRow } from "@mui/material";
import { StrategySignalSwitchEntry } from "../../../types";

export interface StrategyMonitoringSignalTableSwitchRowProps {
  entry: StrategySignalSwitchEntry;
}
export const StrategyMonitoringSignalTableSwitchRow = ({
  entry,
}: StrategyMonitoringSignalTableSwitchRowProps) => (
  <TableRow key={entry.label}>
    <TableCell component="th" scope="row">
      {entry.label} ({entry.description})
    </TableCell>
    <TableCell>{entry.value ? "YES" : "NO"}</TableCell>
    <TableCell colSpan={2}>{entry.overrides?.join(", ")}</TableCell>
  </TableRow>
);
