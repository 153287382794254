import dayjs from "dayjs";
import qs from "query-string";
import { Strategy } from "../types";

export interface StrategyMonitoringFilters {
  strategy: Strategy;
  startDate: Date;
  endDate: Date | null | undefined;
}

const getQueryParams = () => {
  return qs.parse(window.location.search);
};
const buildFiltersFromQueryParams = (
  strategies: Strategy[]
): StrategyMonitoringFilters | null => {
  const queryParams = getQueryParams();
  if (
    !queryParams.ibkr_account_name ||
    !queryParams.instrument_name ||
    !queryParams.strategy_name ||
    !queryParams.start_date
  ) {
    return null;
  }
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    strategy: strategies.find(
      (strategy) =>
        strategy.account.name === queryParams.ibkr_account_name &&
        strategy.instrument.name === queryParams.instrument_name &&
        strategy.name === queryParams.strategy_name
    )!,
    startDate: dayjs(queryParams.start_date as string).toDate(),
    endDate: queryParams.end_date
      ? dayjs(queryParams.end_date as string).toDate()
      : null,
  };
};

const buildDefaultStartDate = () => {
  return dayjs()
    .subtract(2, "days")
    .startOf("day")
    .add(9, "hours")
    .add(30, "minutes")
    .toDate();
};

export const useStrategyMonitoringFiltersFromQueryParams = (
  strategies: Strategy[]
): StrategyMonitoringFilters => {
  const filters = buildFiltersFromQueryParams(strategies);
  if (!filters) {
    return {
      strategy: strategies[0],
      startDate: buildDefaultStartDate(),
      endDate: null,
    };
  }
  return filters;
};

export const reloadWithFiltersIfNecessary = ({
  strategy,
  startDate,
  endDate,
}: StrategyMonitoringFilters) => {
  const currentQueryParams = getQueryParams();
  const queryParams = {
    ibkr_account_name: strategy.account.name,
    instrument_name: strategy.instrument.name,
    strategy_name: strategy.name,
    start_date: startDate.toISOString(),
    end_date: endDate?.toISOString(),
  };
  if (
    currentQueryParams.ibkr_account_name === queryParams.ibkr_account_name &&
    currentQueryParams.instrument_name === queryParams.instrument_name &&
    currentQueryParams.strategy_name === queryParams.strategy_name &&
    currentQueryParams.start_date === queryParams.start_date &&
    currentQueryParams.end_date === queryParams.end_date
  ) {
    return;
  }

  const queryStr = qs.stringify(queryParams);
  window.location.assign(
    `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryStr}`
  );
};

export const reloadWithDefaultFiltersIfNecessary = (strategies: Strategy[]) => {
  const filters = buildFiltersFromQueryParams(strategies);
  if (!filters) {
    reloadWithFiltersIfNecessary({
      strategy: strategies[0],
      startDate: buildDefaultStartDate(),
      endDate: null,
    });
  }
};
