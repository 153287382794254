import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { Strategy, StrategyStats } from "../types";
import { baseUrl } from "./common";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface FetchStrategyStatsParams {
  strategy: Strategy;
}
export const fetchStrategyStats = async ({
  strategy,
}: FetchStrategyStatsParams): Promise<StrategyStats> => {
  const response = await fetch(
    `${baseUrl}/strategies/${strategy.app_id}/stats`,
    {
      mode: "cors",
      method: "GET",
      headers: { Accept: "application/json" },
    }
  );
  const result = (await response.json()) as StrategyStats;
  return result;
};
