import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const dashboardAppConfig = window.dashboardAppConfig;

if (
  (dashboardAppConfig.appEnv === "paper" ||
    dashboardAppConfig.appEnv === "live") &&
  dashboardAppConfig.sentryDsn
) {
  Sentry.init({
    dsn: dashboardAppConfig.sentryDsn,
    environment: dashboardAppConfig.appEnv,
    integrations: [new Sentry.Replay()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [dashboardAppConfig.sentryDsn],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
