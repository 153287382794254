import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { StrategyMonitoringSwitchEvalResultRow } from './StrategyMonitoringSwitchEvalResultRow';
import { StrategyMonitoringRuleEvalResultRow } from './StrategyMonitoringRuleEvalResultRow';
import { useStrategyMonitoringEvalsContext } from '../useStrategyMonitoringEvalsContext';
import { useStrategyMonitoringLiveEvalsContext } from '../useStrategyMonitoringLiveEvalsContext';
import { useStrategyMonitoringFiltersContext } from '../useStrategyMonitoringFiltersContext';
import { StrategyMonitoringOverrideEvalResultRow } from './StrategyMonitoringOverrideEvalResultRow';

export const StrategyMonitoringEvalResults = () => {
  const { periodIndex } = useStrategyMonitoringFiltersContext();
  const { isLoadingEvals } = useStrategyMonitoringEvalsContext();
  const { evals } = useStrategyMonitoringLiveEvalsContext();

  if (isLoadingEvals || !evals || periodIndex === null) {
    return null;
  }

  const result = evals[periodIndex];
  if (!result?.monitoring_eval_results?.switch_results) {
    return null;
  }

  const tableRows = [];
  for (const switchResult of Object.values(result.monitoring_eval_results.switch_results)) {
    tableRows.push(
      <StrategyMonitoringSwitchEvalResultRow
        key={switchResult.switch_tag}
        switchResult={switchResult}
      />,
    );
    for (const ruleResult of Object.values(switchResult.rules)) {
      tableRows.push(
        <StrategyMonitoringRuleEvalResultRow
          key={`${switchResult.switch_tag}+${ruleResult.rule_tag}`}
          parentTag={switchResult.switch_tag}
          ruleResult={ruleResult}
        />,
      );
    }
  }
  for (const overrideResult of Object.values(result.monitoring_eval_results.override_results)) {
    tableRows.push(
      <StrategyMonitoringOverrideEvalResultRow
        key={overrideResult.override_tag}
        overrideResult={overrideResult}
      />,
    );
    for (const ruleResult of Object.values(overrideResult.rules)) {
      tableRows.push(
        <StrategyMonitoringRuleEvalResultRow
          key={`${overrideResult.override_tag}+${ruleResult.rule_tag}`}
          parentTag={overrideResult.override_tag}
          ruleResult={ruleResult}
        />,
      );
    }
  }

  return (
    <Box paddingX={8} paddingY={4}>
      <Typography variant='subtitle1'>
        Period: {dayjs(result.period).format('YYYY-MM-DD HH:mm')}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell>Condition</TableCell>
              <TableCell>Eval</TableCell>
              <TableCell>Satisfied</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
