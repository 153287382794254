import { formatPercentage } from "../../helpers";
import { PerformanceReportResult } from "../../types";
import { PerformanceReportsResultTable } from "./Table";

const columns = ["Rate", "Simple Yearly", "Yearly", "Quarterly", "Monthly"];

export interface PerformanceReportsResultFeeRatesTableProps {
  reportRequestTag: string;
  result: PerformanceReportResult;
}
export const PerformanceReportsResultFeeRatesTable = ({
  reportRequestTag,
  result,
}: PerformanceReportsResultFeeRatesTableProps) => {
  const data: Array<Array<string | number>> = [];

  data.push([
    "Mgmt. Fee",
    formatPercentage(result.management_fee_rates.simple, 4),
    formatPercentage(result.management_fee_rates.yearly, 4),
    formatPercentage(result.management_fee_rates.quarterly, 4),
    formatPercentage(result.management_fee_rates.monthly, 4),
  ]);

  return (
    <PerformanceReportsResultTable
      columns={columns}
      data={data}
      csvFileName={`${reportRequestTag} - Fees Rates.csv`}
    />
  );
};
